import { Info } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { Button } from '@/components/ui';
import { FormEvent, useEffect, useRef, useState } from 'react';
import ReferralCode from './code';
import { Check, Copy } from 'lucide-react';
import { privateApi } from '@/lib/api-clients/rest-client';
import useReferrals from '../api/get-referrals';
import { toast } from 'sonner';
import { useAccountStore } from '@/store/use-account-store';
import { ErrorCode, ErrorMsgs } from '@/types/enums';

export default function ReferralStats() {
  const {
    data: referrals,
    isSuccess,
    isPending,
    isLoading: areReferralsLoading,
    error,
    refetch,
  } = useReferrals();

  const hasAuth = useAccountStore((state) => state.hasAuth);
  const timeoutRef = useRef<number>();
  const [code, setCode] = useState(referrals?.ref_code || '');
  const [isLoading, setIsLoading] = useState(isPending);
  const [copied, setCopied] = useState(false);
  const isCodeEditable =
    !areReferralsLoading && isSuccess && !referrals?.ref_code;

  useEffect(() => {
    setIsLoading(isPending);
  }, [isPending]);

  useEffect(() => {
    if (!copied) return;
    timeoutRef.current = window.setTimeout(() => {
      setCopied(false);
    }, 2000);
    return () => {
      timeoutRef.current && window.clearTimeout(timeoutRef.current);
    };
  }, [copied]);

  useEffect(() => {
    if (!referrals?.ref_code) return;
    setCode(referrals.ref_code);
  }, [referrals?.ref_code]);

  async function saveCode(e: FormEvent) {
    e.preventDefault();
    setIsLoading(true);
    toast.promise(
      privateApi.post('/referrals', {
        refCode: code,
      }),
      {
        loading: 'Claiming referral code...',
        success: async (data) => {
          await refetch();
          setIsLoading(false);
          return `Referral code claimed`;
        },
        error: (error) => {
          setIsLoading(false);
          if (error in ErrorMsgs) {
            if (error === ErrorCode.ACCOUNT_NOT_FOUND) {
              return `Need to deposit first before claiming your referral code.`;
            } else {
              return ErrorMsgs[error as ErrorCode];
            }
          } else {
            return `Error claiming referral code, contact Vest team.`;
          }
        },
      },
    );
  }

  return (
    <div className="flex border-r border-border">
      <div className="flex-1 border-b border-r border-border p-8">
        <div className="flex flex-col gap-3">
          <div className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
            Your code
          </div>
          {hasAuth ? (
            <div className="flex flex-1 justify-between gap-6">
              {!isCodeEditable ? (
                <div className="flex h-10 items-center gap-3">
                  <p className="text-font text-3xl font-bold uppercase">
                    {code || '-'}
                  </p>
                  {code && (
                    <Button
                      size="icon"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `https://trade.vest.exchange/trade/TRUMP-PERP?ref=${code}`,
                        );
                        setCopied(true);
                      }}
                      className="h-10 w-10 rounded-none bg-background font-mono uppercase text-foreground hover:bg-background hover:text-foreground"
                    >
                      {copied ? <Check size={16} /> : <Copy size={16} />}
                    </Button>
                  )}
                </div>
              ) : (
                <ReferralCode value={code} setValue={setCode} />
              )}
              {isCodeEditable && (
                <form onSubmit={saveCode}>
                  <Button
                    disabled={Boolean(
                      code.length !== 5 ||
                        isLoading ||
                        (referrals?.ref_code && code === referrals.ref_code),
                    )}
                    className="h-10 gap-2 rounded-none font-mono uppercase"
                  >
                    <Check size={14} />
                    {isLoading ? 'Claiming...' : 'Claim'}
                  </Button>
                </form>
              )}
            </div>
          ) : (
            <p className="text-font/75 h-10 text-3xl font-bold">-</p>
          )}
        </div>
      </div>
      <div className="flex-1 border-b border-r border-border p-8">
        <div className="flex flex-col gap-3">
          <div className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
            traders referred
          </div>
          <div className="text-font flex h-10 flex-1 items-center justify-start text-3xl font-bold text-vestgrey-600">
            {/* {typeof referrals?.traders_referred === 'number'
              ? referrals.traders_referred
              : 0} */}
            -
          </div>
        </div>
      </div>
      <div className="flex-1 border-b border-r border-border p-8">
        <div className="flex flex-col gap-3">
          <div className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
            volume referred
          </div>
          <div className="text-font flex h-10 flex-1 items-center justify-start text-3xl font-bold text-vestgrey-600">
            {/* {formatNumber(
              typeof referrals?.volume_traded === 'number'
                ? referrals.volume_traded
                : 0,
            )} */}
            -
          </div>
        </div>
      </div>
      <div className="flex-1 border-b border-border p-8">
        <div className="flex flex-col gap-3">
          <h4 className="flex items-center gap-2 font-mono text-sm uppercase tracking-wider text-vestgrey-600">
            Fees Earned{' '}
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger>
                  <Info size={12} />
                </TooltipTrigger>
                <TooltipContent className="max-w-none whitespace-nowrap">
                  FEES ARE DISTRIBUTED EVERY WEDNESDAY 12 PM EST.
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </h4>
          <div className="flex items-center justify-between gap-4">
            <p className="text-3xl font-bold text-vestgrey-600">
              {/* {referrals?.rebate_amount
                ? formatNumber(referrals.rebate_amount)
                : 0} */}
              -
            </p>
            {/* <Rebate /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
