import { zksyncIgniteApi } from '@/lib/api-clients/rest-client';
import { useQuery } from '@tanstack/react-query';
import { useAccount } from 'wagmi';

type Chain = {
  rewards: Reward[];
};

type Reward = {
  amount: string;
  claimed: string;
  pending: string;
  token: {
    decimals: number;
  };
};

async function getRewards(address: string): Promise<Chain[]> {
  const { data } = await zksyncIgniteApi.get(`/users/${address}/rewards`, {
    params: {
      chainId: 324,
    },
  });
  return data;
}

const useIgniteRewards = () => {
  const { address } = useAccount();
  return useQuery({
    queryKey: ['lp', 'ignite-rewards', address],
    queryFn: ({ queryKey: [, , address] }) => getRewards(address!),
    enabled: !!address,
  });
};

export default useIgniteRewards;
