import { zksyncIgniteApi } from '@/lib/api-clients/rest-client';
import { useQuery } from '@tanstack/react-query';

type Opportunity = {
  identifier: string;
  name: string;
  apr: number;
};

async function getOpportunities(): Promise<Opportunity[]> {
  const { data } = await zksyncIgniteApi.get('/opportunities', {
    params: {
      name: 'vest',
    },
  });
  return data;
}

const useOpportunities = () =>
  useQuery({
    queryKey: ['lp', 'opportunities'],
    queryFn: () => getOpportunities(),
  });

export default useOpportunities;
