import { create } from 'zustand';
import { RobustWebsocket } from '@/lib/api-clients/ws-client';
import {
  COLLATERAL_DECIMALS,
  CUM_FUNDING_DECIMALS,
  FUNDING_RATE_DECIMALS,
  MarketSpec,
  useMarketStore,
} from '@/store/use-markets-store';
import { parseDecimalToBigInt } from '@/utils/value-format';
import {
  FilledOrder,
  NewOrder,
  useOrdersStore,
} from '@/store/use-orders-store';
import { toast } from 'sonner';
import {
  getRealizedPnl,
  getSettledFunding,
  getUpdatedAccountOnFill,
} from '@/features/account/utils/math';
import {
  FilledTransfer,
  NewTransfer,
  RejectedTransfer,
  Transfer,
  useTransfersStore,
} from '@/store/use-transfers-store';
import {
  OrderEvent,
  PrivateData,
  OrderStatus,
  OrderType,
  TransferType,
  TransferEvent,
  DataMsg,
} from '@/types';
import { useAccountStore } from './use-account-store';

type WebSocketStore = {
  publicWs: RobustWebsocket | null;
  privateWs: RobustWebsocket | null;
  isUpdated: boolean;
  initializePublicWs: (url: string) => void;
  initializePrivateWs: (url: string) => void;
  disconnectPublicWs: () => void;
  disconnectPrivateWs: () => void;
};

const WS_URL = `${import.meta.env.VITE_WS_URL as string}?version=1.0`;

export const useWebSocketStore = create<WebSocketStore>((set, get) => ({
  publicWs: null,
  privateWs: null,
  isUpdated: false,

  initializePublicWs: (url: string) => {
    const existingWs = get().publicWs;

    // Prevent multiple connections
    if (existingWs) {
      console.warn('Public WebSocket already initialized');
      return;
    }

    const ws = new RobustWebsocket({
      url,
      onConnected: () => {
        ws.subscribeToChannels(['tickers']);
        ws.addMessageHandler('ticker-handler', tickerMsgHandler);
        ws.addMessageHandler('depth-handler', depthMsgHandler);

        set((state) => ({ isUpdated: !state.isUpdated }));
      },
    });

    set({ publicWs: ws });

    ws.safeConnect();
  },

  initializePrivateWs: (url: string) => {
    const existingWs = get().privateWs;

    // Prevent multiple connections
    if (existingWs) {
      console.warn('Private WebSocket already initialized');
      return;
    }

    const ws = new RobustWebsocket({
      url,
      onConnected: () => {
        ws.subscribeToChannels([`account_private`]);
        ws.addMessageHandler('order-handler', orderMsgHandler);
      },
    });

    set({ privateWs: ws });
    ws.safeConnect();
  },

  disconnectPublicWs: () => {
    const ws = get().publicWs;
    if (ws) {
      ws.disconnect(false);
      set({ publicWs: null });
      console.log('Public WebSocket disconnected');
    }
  },

  disconnectPrivateWs: () => {
    const ws = get().privateWs;
    if (ws) {
      ws.disconnect(false);
      set({ privateWs: null });
      console.log('Private WebSocket disconnected');
    }
  },
}));

const tickerMsgHandler = (msg: DataMsg) => {
  if (msg.channel && msg.channel == 'tickers') {
    msg.data.map((ticker: any) => {
      const {
        symbol,
        oneHrFundingRate,
        cumFunding,
        imbalance, // unused for now
        indexPrice,
        markPrice,
        priceChange,
        priceChangePercent,
      } = ticker;
      const { setMarketData, marketData, marketSpec } =
        useMarketStore(symbol).getState();
      setMarketData({
        ...marketData,
        markPrice: parseDecimalToBigInt(markPrice, marketSpec.priceDecimals),
        indexPrice: parseDecimalToBigInt(indexPrice, marketSpec.priceDecimals),
        oneHrFundingRate: parseDecimalToBigInt(
          oneHrFundingRate,
          FUNDING_RATE_DECIMALS,
        ),
        priceChange: parseDecimalToBigInt(
          priceChange,
          marketSpec.priceDecimals,
        ),
        priceChangePct: parseDecimalToBigInt(priceChangePercent, 4),
        cumFunding: parseDecimalToBigInt(cumFunding, CUM_FUNDING_DECIMALS),
      });
    });
    return true;
  }
};

const depthMsgHandler = (msg: DataMsg) => {
  if (msg.channel && msg.channel.endsWith('depth')) {
    const symbol = msg.channel.split('@')[0];
    const { bids, asks } = msg.data;
    const { setBook, marketSpec } = useMarketStore(symbol).getState();
    setBook({
      bids: bids.map(([price, qty]: [string, string]) => {
        return [
          parseDecimalToBigInt(price, marketSpec.priceDecimals),
          parseDecimalToBigInt(qty, marketSpec.sizeDecimals),
        ];
      }),
      asks: asks.map(([price, qty]: [string, string]) => {
        return [
          parseDecimalToBigInt(price, marketSpec.priceDecimals),
          parseDecimalToBigInt(qty, marketSpec.sizeDecimals),
        ];
      }),
    });
    return true;
  }
};

const orderMsgHandler = (msg: DataMsg) => {
  if (msg.channel && msg.channel === 'account_private') {
    const msgData: PrivateData = msg.data;
    const { event } = msgData;

    if (event === 'ORDER') {
      const { args } = msgData as { args: OrderEvent };
      const { setOrder } = useOrdersStore.getState();
      const { account, accountLastUpdated, setAccount, setAccountLastUpdated } =
        useAccountStore.getState();

      let marketSpec: MarketSpec;

      switch (args.status) {
        case OrderStatus.NEW:
          marketSpec = useMarketStore(args.symbol).getState().marketSpec;
          const newOrder: NewOrder = {
            ...args,
            limitPrice: parseDecimalToBigInt(
              args.limitPrice,
              marketSpec.priceDecimals,
            ),
            size: parseDecimalToBigInt(args.size, marketSpec.sizeDecimals),
            initMarginRatio: parseDecimalToBigInt(args.initMarginRatio, 4n),
          };
          setOrder(newOrder);
          return true;

        case OrderStatus.FILLED:
          const marketStore = useMarketStore(args.symbol).getState();
          const marketData = marketStore.marketData;
          marketSpec = marketStore.marketSpec;
          const parsedArgs = {
            ...args,
            limitPrice: parseDecimalToBigInt(
              args.limitPrice,
              marketSpec.priceDecimals,
            ),
            size: parseDecimalToBigInt(args.size, marketSpec.sizeDecimals),
            initMarginRatio: parseDecimalToBigInt(args.initMarginRatio, 4n),
            lastFilledSize: parseDecimalToBigInt(
              args.lastFilledSize,
              marketSpec.sizeDecimals,
            ),
            lastFilledPrice: parseDecimalToBigInt(
              args.lastFilledPrice,
              marketSpec.priceDecimals,
            ),
            avgFilledPrice: parseDecimalToBigInt(
              args.avgFilledPrice,
              marketSpec.priceDecimals,
            ),
            cumFunding: parseDecimalToBigInt(
              args.cumFunding,
              CUM_FUNDING_DECIMALS,
            ),
            fees: parseDecimalToBigInt(args.fees, COLLATERAL_DECIMALS),
          };
          const settledFunding = getSettledFunding(
            parsedArgs.symbol,
            parsedArgs.cumFunding,
            account,
            marketSpec,
          );
          const realizedPnl = getRealizedPnl(
            parsedArgs.symbol,
            parsedArgs.isBuy,
            parsedArgs.lastFilledSize,
            parsedArgs.lastFilledPrice,
            settledFunding.bigint,
            parsedArgs.fees,
            account,
            marketSpec,
          );
          const { cumFunding, ...rest } = parsedArgs;
          const filledOrder: FilledOrder = {
            ...rest,
            realizedPnl: realizedPnl.bigint,
            settledFunding: settledFunding.bigint,
          };
          setOrder(filledOrder);

          // Update account with filled order
          if (filledOrder.lastFilledTime > accountLastUpdated) {
            const updatedAccount = getUpdatedAccountOnFill(
              filledOrder.symbol,
              filledOrder.isBuy,
              filledOrder.lastFilledSize,
              filledOrder.lastFilledPrice,
              filledOrder.realizedPnl,
              filledOrder.initMarginRatio,
              account,
              marketData,
            );
            setAccount(updatedAccount);
            setAccountLastUpdated(filledOrder.lastFilledTime);
          }

          // Display background notifications
          if (filledOrder.orderType === OrderType.LIQUIDATION) {
            toast.success('Liquidated'); // TODO: more sophisticated msgs
          } else if (filledOrder.orderType === OrderType.LIMIT) {
            toast.success('Limit order filled'); // TODO: more sophisticated msgs
          } else if (filledOrder.orderType === OrderType.TAKE_PROFIT) {
            toast.success('Take profit order filled'); // TODO: more sophisticated msgs
          } else if (filledOrder.orderType === OrderType.STOP_LOSS) {
            toast.success('Stop loss order filled'); // TODO: more sophisticated msgs
          }

          return true;

        case OrderStatus.CANCELLED:
          setOrder(args);
          return true;

        case OrderStatus.REJECTED:
          setOrder(args);
          return true;

        default:
          console.error('Unhandled order event: ', args);
          break;
      }
    } else if (event === 'TRANSFER') {
      const { args } = msgData as { args: TransferEvent };
      const { setTransfer, addNewDeposit } = useTransfersStore.getState();
      const { account, accountLastUpdated, setAccount, setAccountLastUpdated } =
        useAccountStore.getState();

      let transfer: Transfer;
      if (args.status === OrderStatus.REJECTED) {
        transfer = args as RejectedTransfer;
        setTransfer(transfer.id, transfer);
        return true;
      } else {
        transfer = {
          ...args,
          size: parseDecimalToBigInt(args.size, COLLATERAL_DECIMALS),
        } as FilledTransfer | NewTransfer;
        setTransfer(transfer.id, transfer);

        if (
          transfer.orderType === TransferType.DEPOSIT &&
          transfer.status === OrderStatus.FILLED
        ) {
          const filledDeposit = transfer as FilledTransfer;
          addNewDeposit(filledDeposit);

          if (filledDeposit.postTime > accountLastUpdated) {
            setAccount({
              ...account,
              collateral: account.collateral + filledDeposit.size,
            });
            setAccountLastUpdated(filledDeposit.postTime);
          }
        } else if (
          transfer.orderType === TransferType.WITHDRAW &&
          transfer.status === OrderStatus.NEW
        ) {
          const newWithdraw = transfer as NewTransfer;

          if (newWithdraw.postTime > accountLastUpdated) {
            setAccount({
              ...account,
              collateral: account.collateral - newWithdraw.size,
            });
            setAccountLastUpdated(newWithdraw.postTime);
          }
        }
        return true;
      }
    }
  }
};
