import { cn } from '@/utils/cn';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
  FUNDING_RATE_DECIMALS,
  useMarketStore,
} from '@/store/use-markets-store';
import { useStore } from 'zustand';
import {
  bigIntToDecimalStr,
  formatNumber,
  parseDecimalToBigInt,
} from '@/utils/value-format';
import { useTickerLatest } from '@/features/markets/api/get-ticker-latest';
import { useTicker24hr } from '@/features/markets/api/get-ticker-24hr';
import useOpenInterest from '@/features/markets/api/get-open-interest';

interface IMarketInfoProps {}

export const MarketInfo: React.FC<IMarketInfoProps> = ({}) => {
  const { symbol } = useParams() as { symbol: string };

  useTickerLatest({ symbols: [symbol] });
  useTicker24hr({ symbols: [symbol], queryConfig: { refetchInterval: 5000 } });
  useOpenInterest({ symbols: [symbol] });
  // TODO: maybe check if ws is connected here? if not display the useTickerLatest data

  const { marketData, marketSpec } = useStore(
    useMarketStore(symbol),
    (state) => state,
  );

  const data = React.useMemo(
    () => ({
      markPrice: bigIntToDecimalStr(
        marketData.markPrice,
        marketSpec.priceDecimals,
      ),
      indexPrice: bigIntToDecimalStr(
        marketData.indexPrice,
        marketSpec.priceDecimals,
      ),
      priceChange: bigIntToDecimalStr(
        marketData.priceChange,
        marketSpec.priceDecimals,
      ),
      priceChangePct: bigIntToDecimalStr(marketData.priceChangePct, 4),
      oneHrFundingRate: bigIntToDecimalStr(
        marketData.oneHrFundingRate * 100n,
        FUNDING_RATE_DECIMALS,
      ),
      fundingApy: bigIntToDecimalStr(
        marketData.oneHrFundingRate * 24n * 365n * 100n,
        FUNDING_RATE_DECIMALS,
      ),
      ...(typeof marketData.openInterest === 'bigint'
        ? {
            openInterest: bigIntToDecimalStr(
              marketData.openInterest,
              marketSpec.priceDecimals + marketSpec.sizeDecimals,
            ),
          }
        : {}),
      ...(typeof marketData.quoteVolume === 'bigint'
        ? {
            quoteVolume: bigIntToDecimalStr(
              marketData.quoteVolume,
              marketSpec.priceDecimals + marketSpec.sizeDecimals,
            ),
          }
        : {}),
    }),
    [marketData, marketSpec],
  );

  const items = [
    {
      title: 'Mark Price',
      value: formatNumber(data?.markPrice, {
        digits: Number(marketSpec.priceDecimals),
      }),
      color: 'text-vestgrey-50',
    },
    {
      title: 'Index Price',
      value: formatNumber(data?.indexPrice, {
        digits: Number(marketSpec.priceDecimals),
      }),
      color: 'text-vestgrey-50',
    },
    {
      title: '24h Change',
      value: `${formatNumber(data?.priceChange, { digits: Number(marketSpec.priceDecimals), showChange: true })} / ${formatNumber(data?.priceChangePct, { style: 'percent', digits: 2, abs: true })}`,
      color:
        data?.priceChange === undefined
          ? 'text-vestgrey-400'
          : Number(data?.priceChange) >= 0n
            ? 'text-green'
            : 'text-red',
    },
    {
      title: '1h Funding',
      value: `${formatNumber(data?.oneHrFundingRate, { style: 'percent', digits: Number(FUNDING_RATE_DECIMALS) - 2 })}`,
      color:
        data?.oneHrFundingRate === undefined
          ? 'text-vestgrey-400'
          : Number(data?.oneHrFundingRate) >= 0n
            ? 'text-green'
            : 'text-red',
    },
    {
      title: 'Funding APY',
      value: `${formatNumber(data?.fundingApy, { style: 'percent', digits: 0 })}`,
      color:
        data?.fundingApy === undefined
          ? 'text-vestgrey-400'
          : Number(data?.fundingApy) >= 0n
            ? 'text-green'
            : 'text-red',
    },
    // {
    //   title: 'Open Interest',
    //   value: formatNumber(data?.openInterest, {
    //     digits: 2,
    //   }),
    //   color: 'text-vestgrey-50',
    // },
    // {
    //   title: '24H Volume',
    //   value: formatNumber(data?.quoteVolume, {
    //     digits: 2,
    //   }),
    //   color: 'text-vestgrey-50',
    // },
  ];

  return (
    <div className="flex items-center gap-8 px-8">
      {items.map((item) => (
        <div
          className={cn(
            'flex flex-col gap-0.5',
            item.title === '24h Change' ? 'min-w-44' : 'min-w-36',
          )}
          key={item.title}
        >
          <div className="flex flex-1 items-center justify-start font-mono text-sm capitalize text-vestgrey-600">
            {item.title.toUpperCase()}
          </div>
          <div
            className={cn(
              'flex flex-1 items-center justify-start text-xl font-bold text-vestgrey-200',
              item.color,
            )}
          >
            {item.value}
          </div>
        </div>
      ))}
    </div>
  );
};
