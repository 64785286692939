import { useFetchAccount } from '@/features/account/api/get-account';
import { cn } from '@/lib/utils';
import { LPOrderType, OrderStatus } from '@/types/enums';
import {
  bigIntToDecimalStr,
  bigIntToNum,
  formatBigInt,
  formatNumber,
  parseDecimalToBigInt,
} from '@/utils/value-format';
import { useLpOrders } from '../api/get-lp-orders';
import { COLLATERAL_DECIMALS, useMarketStore } from '@/store/use-markets-store';
import { useMemo } from 'react';
import { LPTransferDialog } from './lp-transfer-dialog';
import { useAccountStore } from '@/store/use-account-store';
import useIgniteRewards from '../api/get-ignite-rewards';
import { useExchangeInfo } from '@/features/markets/api/get-exchange-info';
import { useStore } from 'zustand';

export default function LPStats() {
  const { isPending: isExchangeInfoPending, isError: isExchangeInfoError } =
    useExchangeInfo({});
  const { data: account } = useFetchAccount();
  const { hasAuth } = useAccountStore();
  const { data: igniteChains } = useIgniteRewards();
  const { marketData, marketSpec } = useStore(
    useMarketStore('ZK-PERP'),
    (state) => state,
  );

  const { data: lpOrders } = useLpOrders({
    orderType: LPOrderType.SCHEDULE_WITHDRAW,
    status: OrderStatus.NEW,
  });

  const totalIgniteRewards = useMemo(() => {
    if (
      !igniteChains ||
      igniteChains.length === 0 ||
      isExchangeInfoError ||
      isExchangeInfoPending
    )
      return 0;
    return igniteChains[0].rewards.reduce(
      (prev, { pending, token }) =>
        prev +
        bigIntToNum(BigInt(pending), token.decimals) *
          bigIntToNum(marketData.indexPrice, marketSpec.priceDecimals),
      0,
    );
  }, [
    igniteChains,
    isExchangeInfoPending,
    isExchangeInfoError,
    marketData.indexPrice,
    marketSpec.priceDecimals,
  ]);

  const pendingWithdrawal: bigint | undefined = useMemo(() => {
    return lpOrders?.reduce(
      (acc, order) =>
        acc + parseDecimalToBigInt(order.size, COLLATERAL_DECIMALS),
      0n,
    );
  }, [lpOrders]);

  return (
    <div className="grid grid-cols-2 border-r border-border">
      <div className="col-span-2 border-b border-border p-8">
        <div className="flex flex-col gap-4">
          <h4 className="font-mono text-sm uppercase tracking-wider text-vestgrey-600">
            Current Balance
          </h4>
          <div className="flex items-center justify-between">
            <strong className="text-font flex flex-1 items-center justify-start text-3xl lg:text-4xl">
              {formatNumber(account?.lp.balance, { digits: 2 })}
            </strong>
            {account && Number(account.lp.balance) > 0 && (
              <LPTransferDialog orderType={LPOrderType.SCHEDULE_WITHDRAW}>
                <button
                  className="bg-vestgrey-alt px-8 py-2 font-mono text-sm uppercase tracking-widest"
                  disabled={!hasAuth}
                >
                  Withdraw
                </button>
              </LPTransferDialog>
            )}
          </div>
        </div>
      </div>
      <div className="border-b border-r border-border px-8 py-6">
        <div className="flex flex-col gap-2">
          <div className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
            Total Unrealized Pnl
          </div>
          <div
            className={cn(
              'text-font flex flex-1 items-center justify-start text-xl font-bold',

              account
                ? Number(account.lp.unrealizedPnl) >= 0
                  ? 'text-green'
                  : 'text-red'
                : undefined,
            )}
          >
            {formatNumber(account?.lp.unrealizedPnl, {
              digits: 2,
              showChange: true,
            })}
          </div>
        </div>
      </div>
      <div className="border-b border-border px-8 py-6">
        <div className="flex flex-col gap-2">
          <div className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
            Ignite Rewards
          </div>
          <div className="text-font flex flex-1 items-center justify-start text-xl font-bold">
            {formatNumber(totalIgniteRewards, { maxDigits: 2 })}
          </div>
        </div>
      </div>
      <div className="border-b border-r border-border px-8 py-6">
        <div className="flex flex-col gap-2">
          <div className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
            Pending Withdrawal
          </div>
          <div className="text-font flex flex-1 items-center justify-start text-xl font-bold">
            {formatBigInt(pendingWithdrawal, COLLATERAL_DECIMALS, {
              digits: 2,
            })}
          </div>
        </div>
      </div>
      <div className="border-b border-border px-8 py-6"></div>
    </div>
  );
}
