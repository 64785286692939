// FORMAT FOR CALCULATIONS

import { OrderStatus, OrderType, TransferType } from '@/types/enums';

export interface BigDecimal {
  bigint: bigint;
  decimal: string;
}

export function parseDecimalToBigInt(
  decimalStr: string,
  decimals: number | bigint,
): bigint {
  try {
    let [whole, fraction = ''] = decimalStr.split('.');
    if (fraction.length > Number(decimals)) {
      // truncate
      // console.debug(
      //   `DecimalStr ${decimalStr} is longer than decimals ${decimals}, truncating`,
      // );
      fraction = fraction.slice(0, Number(decimals));
    } else if (fraction.length < Number(decimals)) {
      fraction = fraction.padEnd(Number(decimals), '0');
    }
    return BigInt(whole + fraction);
  } catch (e) {
    throw e;
  }
}

export function parseInputToDecimalStr(
  value: string,
  decimals: number | bigint,
): string {
  const parsed = parseDecimalToBigInt(value, decimals);
  return bigIntToDecimalStr(parsed, decimals);
}

export function bigIntToDecimalStr(value: bigint, decimals: number | bigint) {
  if (Number(decimals) === 0) {
    return value.toString();
  }

  const absVal = value >= 0n ? value : -value;
  const sign = value >= 0n ? '' : '-';
  const valStr = absVal.toString();
  const decimalPointIdx = valStr.length - Number(decimals);
  let decimalStr: string;
  if (decimalPointIdx <= 0) {
    decimalStr =
      sign + '0.' + valStr.padStart(valStr.length - decimalPointIdx, '0');
  } else {
    decimalStr =
      sign +
      valStr.slice(0, decimalPointIdx) +
      '.' +
      valStr.slice(decimalPointIdx);
  }
  return decimalStr;
}

// This function helps avoid BigInt negative exponent out of range error.
// It also deals with rounding
export function adjustDecimals(
  value: bigint,
  decimalsDelta: number | bigint,
  round: 'floor' | 'ceil' = 'floor',
) {
  decimalsDelta = BigInt(decimalsDelta);
  if (decimalsDelta === 0n) return value;
  if (decimalsDelta > 0n) return value * 10n ** decimalsDelta;

  const quotient = value / 10n ** -decimalsDelta;
  if (round === 'floor') {
    return quotient;
  }

  // ceil
  const remainder = value - quotient * 10n ** -decimalsDelta;
  if (remainder === 0n) {
    return quotient;
  } else {
    return quotient + 1n;
  }
}

// export function bigIntToInt(value: bigint, decimals: number | bigint, round: 'floor' | 'ceil' | 'round' = 'floor') {
//   const decimalStr = bigIntToDecimalStr(value, decimals);
//   return Number(decimalStr);
// }

// FORMAT FOR DISPLAYING

// THIS FUNCTION IS FOR DISPLAYING VALUES NOT FOR CALCULATIONS!
export function bigIntToNum(value: bigint, decimals: number | bigint): number {
  const decimalStr = bigIntToDecimalStr(value, decimals);
  return Number(decimalStr);
}

export const removeInsignificantZeros = (
  value: string,
  minDecimals: number = 0,
) => {
  // don't format numbers without decimal point
  if (!value.includes('.')) return value;
  for (let i = value.length - 1; i >= minDecimals + 1; i -= 1) {
    if (value.charAt(i) === '0' && value.charAt(i - minDecimals) !== '.') {
      // trim insignificant zeros from the end of decimal string leaving min decimals in place
      value = value.substring(0, i) + value.substring(i + 1);
    } else {
      break;
    }
  }
  return value;
};

type ValueFormatOptions = {
  style?: 'currency' | 'percent' | 'decimal' | 'leverage' | 'slippage' | null;
  digits?: number | null; // enforces exactly digits
  showChange?: boolean;
  compact?: boolean;
  // showCurrency?: boolean;
  round?: 'floor' | 'ceil' | 'expand' | 'trunc';
  abs?: boolean;
  commas?: boolean;
  maxDigits?: number | null;
};

/**
 * Format a decimal or integer value. Includes comma separators.
 *
 * @param {*} value The value to be formatted
 * @param {*} style 'currency', 'percent', or null (or 'decimal') for default value formatting
 * @param {*} digits The number of fractional digits the number should have. If null (default), then uses the original number
 * @param {*} showChange Whether to include `+` before positive numbers. default: false
 * @param {*} compact Whether to compact the number in scientific form. default: false
 * @param {*} round Whether to round the number. default: 'floor'
 * @returns A string representing the value
 */
export function formatNumber(
  rawValue: number | string | undefined,
  options: ValueFormatOptions = {},
) {
  if (rawValue === undefined) return '-';

  let value = rawValue;
  if (options.style === 'slippage') {
    value = Math.max(0, Number(rawValue));
  }

  const formattedValue = new Intl.NumberFormat('en-US', {
    // style: style === "percent" ? "percent" : "decimal",
    // ...(style === 'currency' && showCurrency
    //   ? { style: 'currency', currency: 'USD' })
    // : { style: style === 'percent' ? 'percent' : 'decimal' }),
    notation: options.compact ? 'compact' : 'standard',
    minimumFractionDigits: options.digits ?? 0,
    maximumFractionDigits: options.digits ?? options.maxDigits ?? 20,
    signDisplay: options.abs ? 'never' : options.showChange ? 'always' : 'auto',
    // @ts-ignore
    roundingMode: options.round ?? 'floor',
    useGrouping: options.commas ?? true,
  }).format(Number(value));

  // return style !== 'percent'
  //   ? removeInsignificantZeros(
  //       formattedValue,
  //       (digits || 0) > 2 ? 4 : digits || 0,
  //     )
  //   : formattedValue;

  let suffix = '';
  switch (options.style) {
    case 'slippage':
    case 'percent':
      suffix = '%';
      break;
    case 'leverage':
      suffix = 'X';
      break;
    default:
      suffix = '';
      break;
  }

  return removeInsignificantZeros(formattedValue, options.digits ?? 0) + suffix;
}

export function formatBigInt(
  value: bigint | undefined,
  decimals: number | bigint,
  options: ValueFormatOptions = {},
) {
  return formatNumber(
    value === undefined ? undefined : bigIntToNum(value, decimals),
    options,
  );
}

export const validatedDecimalStr = (
  value: string,
  maxDecimals: number,
  maxLength: number,
  allowSign: boolean = false,
) => {
  // Preserve sign if allowed
  const sign =
    allowSign && (value.startsWith('+') || value.startsWith('-'))
      ? value[0]
      : '';

  // Remove sign from value for processing
  let validatedVal = value.replace(/^[+-]/, '').replace(/[^0-9.]/g, '');

  // truncate decimals
  const match = validatedVal.match(
    new RegExp(`^(\\d+(\\.\\d{0,${maxDecimals}})?)?`),
  );
  if (match) {
    validatedVal = match[0];
  }

  if (validatedVal.length > maxLength) {
    validatedVal = validatedVal.slice(0, maxLength);
  }

  return sign + validatedVal;
};

type formatDateOptions = {
  showSeconds?: boolean;
  showTime?: boolean;
};

export const formatDate = (
  date: Date | number,
  { showSeconds, showTime }: formatDateOptions = {},
) => {
  const dateStr = new Intl.DateTimeFormat('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
  }).format(date);

  if (!showTime) {
    return dateStr;
  }

  const timeStr = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: showSeconds ? '2-digit' : undefined,
    hour12: false,
  }).format(date);

  return `${dateStr} ${timeStr}`;
};

export const formatTimeDelta = (date: Date) => {
  return `${date.getHours()} hours`;
};

export const formatOrderType = (type: OrderType) => {
  switch (type) {
    case OrderType.MARKET:
      return 'Market';
    case OrderType.LIMIT:
      return 'Limit';
    case OrderType.STOP_LOSS:
      return 'Stop Loss';
    case OrderType.TAKE_PROFIT:
      return 'Take Profit';
    case OrderType.LIQUIDATION:
      return 'Liquidation';
    default:
      return '-';
  }
};

export const formatOrderStatus = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.NEW:
      return 'New';
    case OrderStatus.PARTIALLY_FILLED:
      return 'Partially Filled';
    case OrderStatus.FILLED:
      return 'Filled';
    case OrderStatus.CANCELLED:
      return 'Cancelled';
    case OrderStatus.REJECTED:
      return 'Rejected';
    default:
      return '-';
  }
};

export const formatTransferType = (type: TransferType) => {
  switch (type) {
    case TransferType.DEPOSIT:
      return 'Deposit';
    case TransferType.WITHDRAW:
      return 'Withdraw';
    default:
      return '-';
  }
};
