import { Button } from '../ui';

export default function RealtimeConnectionLostBanner() {
  return (
    <div className="flex items-center gap-4 bg-brown px-8 py-4">
      <p className="text-lg tracking-wider text-primary">
        <strong>Connection Lost.</strong>
        &nbsp;Your realtime connection has been interrupted. Please refresh the
        page to restore it.
      </p>
      <Button
        onClick={() => window.location.reload()}
        className="rounded-none font-mono font-normal tracking-wider"
      >
        REFRESH PAGE
      </Button>
    </div>
  );
}
