import * as React from 'react';
import { createRoot } from 'react-dom/client';

import './index.scss';
import { App } from './app';
import { Toaster } from './components/ui';
import * as Sentry from '@sentry/react';

if (import.meta.env.VITE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://acff0c0ccfe3543bdf0caacd586b8a5a@o4505358387511296.ingest.us.sentry.io/4508451144728576',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      // /^https:\/\/server-mmdev\.vest\.exchange\/v2/,
      /^https:\/\/serverprod\.vest\.exchange\/v2/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = document.getElementById('root');
if (!root) throw new Error('No root element found');

// TODO: Add testing
// enableMocking().then(() => {
createRoot(root).render(
  <React.StrictMode>
    <App />
    <Toaster closeButton />
  </React.StrictMode>,
);
// })
