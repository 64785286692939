export default function SystemClockBanner() {
  return (
    <div className="flex items-center bg-brown px-8 py-4">
      <p className="text-lg tracking-wider text-primary">
        <strong>Your system clock is out of sync.</strong> Please update it to
        the correct time to avoid order issues. If unsynced, orders may expire,
        but we will retry using accurate time from trusted sources.
      </p>
    </div>
  );
}
