import { useAccountStore } from '@/store/use-account-store';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import accountRewards from './account_rewards.json';

export default function AnnouncementBanner() {
  const { hasAuth } = useAccountStore();
  const { address } = useAccount();
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    setAmount(0);
    if (!hasAuth || !address) return;
    if (address in accountRewards) {
      setAmount(accountRewards[address as keyof typeof accountRewards]);
    }
  }, [hasAuth, address]);

  if (amount <= 0) return;

  return (
    <div className="flex items-center border-b border-border px-8 py-4">
      <p className="text-lg tracking-wider">
        <strong className="mr-2 inline-block">
          🎉 ZK Ignite Rewards Distributed!
        </strong>{' '}
        <span>
          We've distributed a total of {amount} ZK directly to your wallet.
        </span>
      </p>
    </div>
  );
}
