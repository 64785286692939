import { queryOptions, useQuery } from '@tanstack/react-query';
import { QueryConfig } from '@/lib/api-clients/react-query';
import { privateApi } from '@/lib/api-clients/rest-client';
import { useAccountStore } from '@/store/use-account-store';
import { OrderStatus, TransferType } from '@/types/enums';
import {
  FilledTransfer,
  NewTransfer,
  useTransfersStore,
} from '@/store/use-transfers-store';
import { COLLATERAL_DECIMALS } from '@/store/use-markets-store';
import { parseDecimalToBigInt } from '@/utils/value-format';

interface GetTransfersParams {
  id?: string;
  orderType?: string;
  startTime?: number;
  endTime?: number;
  limit?: number;
}

export type NewTransferResponse = {
  id: string;
  size: string;
  orderType: TransferType;
  status: OrderStatus.NEW;
  chainId: number;
  postTime: number;
};

export type FilledTransferResponse = {
  id: string;
  size: string;
  orderType: TransferType;
  status: OrderStatus.FILLED;
  chainId: number;
  postTime: number;
};

export type RejectedTransferResponse = {
  id: string;
  orderType: TransferType;
  status: OrderStatus.REJECTED;
  code: number;
  chainId: number;
  postTime: number;
};

export type TransferResponse =
  | NewTransferResponse
  | FilledTransferResponse
  | RejectedTransferResponse;

export const getTransfers = async ({
  id,
  orderType,
  startTime,
  endTime = useAccountStore.getState().getAccurateTime(),
  limit = 1000,
}: GetTransfersParams): Promise<TransferResponse[]> => {
  return privateApi
    .get('/transfer', {
      params: {
        id,
        orderType,
        startTime,
        endTime,
        limit,
      },
    })
    .then((res: TransferResponse[]) => {
      const { setTransfer, addNewDeposit } = useTransfersStore.getState();
      res.forEach((transfer) => {
        if (transfer.status === OrderStatus.REJECTED) {
          setTransfer(transfer.id, transfer);
        } else {
          const parsedTransfer = {
            ...transfer,
            size: parseDecimalToBigInt(transfer.size, COLLATERAL_DECIMALS),
          };

          setTransfer(transfer.id, parsedTransfer);

          if (
            transfer.orderType === TransferType.DEPOSIT &&
            transfer.status === OrderStatus.FILLED
          ) {
            const {
              account,
              accountLastUpdated,
              setAccount,
              setAccountLastUpdated,
            } = useAccountStore.getState();
            const filledDeposit = parsedTransfer as FilledTransfer;
            addNewDeposit(filledDeposit);

            if (filledDeposit.postTime > accountLastUpdated) {
              setAccount({
                ...account,
                collateral: account.collateral + filledDeposit.size,
              });
              setAccountLastUpdated(filledDeposit.postTime);
            }
          } else if (
            transfer.orderType === TransferType.WITHDRAW &&
            transfer.status === OrderStatus.NEW
          ) {
            const {
              account,
              accountLastUpdated,
              setAccount,
              setAccountLastUpdated,
            } = useAccountStore.getState();
            const newWithdraw = parsedTransfer as NewTransfer;

            if (newWithdraw.postTime > accountLastUpdated) {
              setAccount({
                ...account,
                collateral: account.collateral - newWithdraw.size,
              });
              setAccountLastUpdated(newWithdraw.postTime);
            }
          }
        }
      });
      return res;
    });
};

export const getTransfersQueryOptions = (params: GetTransfersParams) => {
  const { endTime, limit, ...restOfParams } = params;
  const address = useAccountStore.getState().authParams?.address;
  const paramsToCache = { ...restOfParams, address };
  return queryOptions({
    queryKey: ['transfers', paramsToCache],
    queryFn: () => getTransfers(params),
  });
};

type UseTransfersOptions = GetTransfersParams & {
  queryConfig?: QueryConfig<typeof getTransfersQueryOptions>;
};

export const useTransfers = ({
  queryConfig,
  ...params
}: UseTransfersOptions = {}) => {
  return useQuery({
    ...getTransfersQueryOptions(params),
    ...queryConfig,
  });
};
