import { Outlet } from 'react-router-dom';
import { Header } from '@/components/header';
import GeofenceBanner from '../header/geofence-banner';
import useGeofenceCheck from '@/utils/geofence-check';
import ErrorBoundary from '@/app/error-boundary';
import { useAccurateTimeCheck } from '@/hooks';
import SystemClockBanner from '../header/system-clock-banner';
import BugReport from '../bug-report';
import { useAccountStore } from '@/store/use-account-store';
import { useEffect, useState } from 'react';
import { ErrorCode, ErrorMsgs } from '@/types';
import { useWebSocketStore } from '@/store/use-websocket-store';
import { toast } from 'sonner';
import { logConsole } from '@/lib/utils';
import { useListenKey } from '@/lib/api/listen-key';
import RealtimeConnectionLostBanner from '../header/realtime-connection-lost-banner';
import AnnouncementBanner from '../header/announcement-banner';

export default function RootLayout() {
  const { data: isSanctioned } = useGeofenceCheck();
  const { isAccurate: isSystemClockAccurate, offset } = useAccurateTimeCheck();
  const { setTimeOffset, hasAuth } = useAccountStore((state) => ({
    setTimeOffset: state.setTimeOffset,
    hasAuth: state.hasAuth,
  }));
  const { publicWs, privateWs } = useWebSocketStore();
  const { mutateAsync: getListenKey } = useListenKey();
  const [isRealTimeConnectionLost, setIRealTimeConnectionLost] =
    useState<boolean>(false);

  useEffect(() => {
    publicWs?.attachOnError((error) => {
      if (typeof error === 'string' || error === ErrorCode.MAX_RETRIES_EXCEEDED)
        setIRealTimeConnectionLost(true);
      else toast.error(ErrorMsgs[error]);

      logConsole(true, 'PublicWS Error', error);
    });
  }, [publicWs]);

  useEffect(() => {
    privateWs?.attachOnError((error) => {
      if (error === ErrorCode.INVALID_LISTEN_KEY && hasAuth) {
        getListenKey();
      }
      if (typeof error === 'string' || error === ErrorCode.MAX_RETRIES_EXCEEDED)
        setIRealTimeConnectionLost(true);
      // else toast.error(ErrorMsgs[error]);

      logConsole(true, 'PrivateWS Error', error);
    });
  }, [privateWs]);

  useEffect(() => {
    setTimeOffset(offset);
  }, [offset]);

  return (
    <ErrorBoundary>
      <div className="flex h-full flex-col">
        {isRealTimeConnectionLost && <RealtimeConnectionLostBanner />}
        {isSanctioned ? (
          <GeofenceBanner />
        ) : (
          !isSystemClockAccurate && <SystemClockBanner />
        )}
        <AnnouncementBanner />
        <Header />
        <main className="h-full">
          <div className="hidden h-full md:block">
            <Outlet />
          </div>
          <div className="flex h-full items-center justify-center md:hidden">
            <p className="text-bold w-full max-w-xs text-center font-mono text-xl uppercase">
              Mobile trading coming soon
            </p>
          </div>
        </main>
      </div>
      {import.meta.env.VITE_ENV === 'production' && <BugReport />}
    </ErrorBoundary>
  );
}
