import { queryOptions, useQuery } from '@tanstack/react-query';
import { publicApi } from '@/lib/api-clients/rest-client';
import { QueryConfig } from '@/lib/api-clients/react-query';
import { useMarketStore } from '@/store/use-markets-store';
import { parseDecimalToBigInt } from '@/utils/value-format';

// TODO: Move to /markets/types or /src/types
export type Ticker24hr = {
  symbol: string;
  openPrice: string;
  closePrice: string;
  highPrice: string;
  lowPrice: string;
  quoteVolume: string;
  volume: string;
  priceChange: string;
  priceChangePercent: string;
  openTime: number;
  closeTime: number;
};

// TODO: Move to /markets/types or /src/types
export type Ticker24hrResponse = {
  tickers: Ticker24hr[];
};

export const getTicker24hr = (
  symbols?: string[],
): Promise<Ticker24hrResponse> => {
  return publicApi
    .get('/ticker/24hr', {
      params: {
        symbols: symbols?.join(','),
      },
    })
    .then((res: Ticker24hrResponse) => {
      res.tickers.forEach((ticker: Ticker24hr) => {
        const { setMarketData, marketData, marketSpec } = useMarketStore(
          ticker.symbol,
        ).getState();
        setMarketData({
          ...marketData,
          volume: parseDecimalToBigInt(ticker.volume, marketSpec.sizeDecimals),
          quoteVolume: parseDecimalToBigInt(
            ticker.quoteVolume,
            marketSpec.priceDecimals + marketSpec.sizeDecimals,
          ),
        });
      });
      return res;
    });
};

export const getTicker24hrQueryOptions = ({
  symbols,
}: { symbols?: string[] } = {}) => {
  return queryOptions({
    queryKey: symbols ? ['ticker24hr', { symbols }] : ['ticker24hr'],
    queryFn: () => getTicker24hr(symbols),
  });
};

type UseTicker24hrOptions = {
  symbols?: string[];
  queryConfig?: QueryConfig<typeof getTicker24hrQueryOptions>;
};

export const useTicker24hr = ({
  queryConfig,
  symbols,
}: UseTicker24hrOptions) => {
  return useQuery({
    ...getTicker24hrQueryOptions({ symbols }),
    ...queryConfig,
  });
};
