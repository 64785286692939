import React, { useState, useMemo, useEffect } from 'react';
import {
  FormControl,
  FormItem,
  FormLabel,
} from '@/features/trade/order/components/order-form-components';
import { Input } from '@/components/ui';
import {
  parseDecimalToBigInt,
  validatedDecimalStr,
} from '@/utils/value-format';
import { useMarketStore } from '@/store/use-markets-store';
import { set, useFormContext } from 'react-hook-form';

interface PriceInputProps {
  symbol: string;
  isSuccess?: boolean;
  onPriceChange: (price: string) => void;
  defaultValue?: string;
}

export const PriceInput: React.FC<PriceInputProps> = ({
  symbol,
  isSuccess,
  onPriceChange,
  defaultValue = '',
}) => {
  const { formState } = useFormContext();

  const [priceInput, setPriceInput] = useState<string>(defaultValue);

  const marketSpec = useMemo(() => {
    return useMarketStore(symbol).getState().marketSpec; // TODO: is ready hook
  }, [symbol]);

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validatedValue = validatedDecimalStr(
      e.target.value,
      Number(marketSpec.priceDecimals),
      10,
    );
    setPriceInput(validatedValue);
    onPriceChange(validatedValue);
  };

  useEffect(() => {
    if (formState.isSubmitting || !isSuccess) return;
    setPriceInput('');
  }, [formState.isSubmitting, isSuccess]);

  return (
    <FormItem className="h-10">
      <p className="text-vestgrey-200">Limit Price</p>
      <div className="flex items-center justify-end gap-2">
        <FormControl>
          <Input
            className="max-w-[17ch] rounded-none bg-vestgrey-800 font-mono text-base text-foreground"
            value={priceInput}
            onChange={handlePriceChange}
          />
        </FormControl>
      </div>
    </FormItem>
  );
};
