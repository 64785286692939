import { useRef, useEffect, useState, useMemo } from 'react';
import getDatafeed from '@/lib/tv/datafeed';
import getTimezoneId from '@/utils/get-timezones';
import {
  widget as Widget,
  IChartingLibraryWidget,
  ResolutionString,
  IPositionLineAdapter,
  IOrderLineAdapter,
  LineToolsAndGroupsState,
  // EntityId,
  // IChartWidgetApi,
  // } from '@/lib/tv/charting_library';
} from '../../../../public/charting_library';
import { useExchangeInfo } from '@/features/markets/api/get-exchange-info';
import { useAccountStore } from '@/store/use-account-store';
import {
  bigIntToDecimalStr,
  bigIntToNum,
  formatNumber,
} from '@/utils/value-format';
import { useMarketStores } from '@/store/use-markets-store';
import {
  getPositionLiqPrice,
  getPositionPnl,
} from '@/features/account/utils/math';
import { useOrdersStore } from '@/store/use-orders-store';
import { useShallow } from 'zustand/react/shallow';
import { OrderType } from '@/types/enums';
import { useWebSocketStore } from '@/store/use-websocket-store';
import { useOrders } from '@/features/account/api/get-orders';
import { useFetchAccount } from '@/features/account/api/get-account';
import { useWindowWidth } from '@/hooks';

const mobileDisabledFeatures = [
  'left_toolbar',
  'control_bar',
  'header_widget',
  'legend_widget',
  'main_series_scale_menu',
];

interface ITradingView {
  symbol: string;
}

const stringifyLineTools = (state: LineToolsAndGroupsState) =>
  JSON.stringify({
    ...state,
    sources: state.sources ? Array.from(state.sources.entries()) : [],
    groups: Array.from(state.groups.entries()),
  });

export default function TradingView({ symbol }: ITradingView) {
  const account = useAccountStore((state) => state.account);
  const { isPending: isExchangeInfoPending, isError: isExchangeInfoError } =
    useExchangeInfo({});

  useOrders();

  const [chartDataLoaded, setChartDataLoaded] = useState(false);
  const positionLineRef = useRef<IPositionLineAdapter>();
  const liquidationLineRef = useRef<IPositionLineAdapter>();
  const orderLines = useRef<IOrderLineAdapter[]>([]);

  const [interval, setInterval] = useState(
    localStorage.getItem('tvInterval') || '1',
  );
  const [tvWidget, setTvWidget] = useState<IChartingLibraryWidget | null>(null);

  const { isMobile, isTablet } = useWindowWidth();

  const markets = useMarketStores((state) => ({
    marketData: state.marketData,
    marketSpec: state.marketSpec,
  }));

  const { publicWs, isUpdated } = useWebSocketStore((state) => ({
    publicWs: state.publicWs,
    isUpdated: state.isUpdated,
  }));

  const ordersStore = useOrdersStore(
    useShallow((state) =>
      Array.from(state.newOrderIds, (id) => state.orders[id]).filter(
        (order) =>
          order.symbol === symbol &&
          (order.orderType === OrderType.LIMIT ||
            order.orderType === OrderType.TAKE_PROFIT ||
            order.orderType === OrderType.STOP_LOSS),
      ),
    ),
  );

  const hasAuth = useAccountStore((state) => state.hasAuth);

  const orders = useMemo(
    () =>
      ordersStore.map(({ id, symbol, orderType, limitPrice, size, isBuy }) => ({
        id,
        symbol,
        orderType,
        limitPrice,
        size,
        isBuy,
      })),
    [ordersStore],
  );

  const { priceDecimals, sizeDecimals } = markets[symbol].marketSpec;

  useEffect(() => {
    if (
      isExchangeInfoPending ||
      isExchangeInfoError ||
      !publicWs ||
      !publicWs.isReady()
    )
      return;

    const datafeed = getDatafeed(publicWs);
    const nowSecs = Date.now() / 1000;
    const intervalMins = parseInt(interval);
    const widget = new Widget({
      container: document.getElementById('chart-container'),
      datafeed,
      library_path: '/charting_library/',
      debug: false,
      fullscreen: false,
      symbol,
      interval,
      timeframe: { from: nowSecs - 120 * intervalMins * 60, to: nowSecs }, // have 120 bars on screen
      theme: 'dark',
      allow_symbol_change: false,
      timezone: getTimezoneId(),
      autosize: true,
      custom_css_url: '/tradingview.css',
      // toolbar_bg: isMobile ? '#0F0E0C' : '#1A1A1A',
      toolbar_bg: '#0F0F0F',
      loading_screen: {
        // backgroundColor: isMobile ? '#0F0E0C' : '#1A1A1A',
        backgroundColor: '#0F0F0F',
        foregroundColor: '#FF7456',
      },
      overrides: {
        'mainSeriesProperties.candleStyle.upColor': '#4BC2A3',
        'mainSeriesProperties.candleStyle.downColor': '#E03737',
        'mainSeriesProperties.candleStyle.borderUpColor': '#4BC2A3',
        'mainSeriesProperties.candleStyle.borderDownColor': '#E03737',
        'mainSeriesProperties.candleStyle.wickUpColor': '#4BC2A3',
        'mainSeriesProperties.candleStyle.wickDownColor': '#E03737',
        'paneProperties.backgroundType': 'solid',
        // 'paneProperties.background': isMobile ? '#0F0E0C' : '#1A1A1A',
        'paneProperties.background': '#0F0F0F',
        'paneProperties.vertGridProperties.color':
          isMobile || isTablet ? 'transparent' : '#222127',
        'paneProperties.horzGridProperties.color':
          isMobile || isTablet ? 'transparent' : '#222127',
        'scalesProperties.lineColor':
          isMobile || isTablet ? '#0F0E0C' : '#AEADAD',
      },
      disabled_features: [
        'header_symbol_search',
        'use_localstorage_for_settings',
        'timeframes_toolbar',
        'header_undo_redo',
        ...(isMobile || isTablet ? mobileDisabledFeatures : []),
      ],
      enabled_features: ['saveload_separate_drawings_storage'],
      // save_load_adapter: new LocalStorageDrawingsPerSymbolSaveLoadAdapter(),
    } as any);

    widget.onChartReady(() => {
      const chart = widget.chart();

      chart.onIntervalChanged().subscribe(null, (interval: string) => {
        localStorage.setItem('tvInterval', interval);
        setInterval(interval);
      });

      chart.onDataLoaded().subscribe(
        null,
        () => {
          setChartDataLoaded(true);
          chart.refreshMarks();
        },
        false,
      );

      if (!chart.dataReady()) {
        if (isMobile || isTablet) {
          widget.remove();
        }
      }

      setTvWidget(widget);
    });
  }, [isExchangeInfoPending, isExchangeInfoError, isUpdated]);

  useEffect(() => {
    if (!tvWidget) return;
    const onDraw = (_id: string, type: string) => {
      try {
        const chart = tvWidget.chart();
        if (!chart.dataReady() || type === 'properties_changed') return;
        const drawingsState = chart.getLineToolsState();
        localStorage.setItem(
          `tv-chart-drawings-${chart.symbol()}`,
          stringifyLineTools(drawingsState),
        );
      } catch (err) {
        console.warn("Couldn't save drawing", err);
      }
    };
    setChartDataLoaded(false);
    try {
      const chart = tvWidget.chart();

      chart.clearMarks();

      tvWidget.setSymbol(symbol, interval as ResolutionString, () => {});

      const tvChartDrawings = localStorage.getItem(
        `tv-chart-drawings-${symbol}`,
      );

      if (tvChartDrawings) {
        const state = JSON.parse(tvChartDrawings);
        chart.applyLineToolsState({
          ...state,
          sources: new Map(state.sources),
          groups: new Map(state.groups),
        });
      }

      chart.dataReady(() => {
        tvWidget.subscribe('drawing_event', onDraw);
      });

      // chart.onDataLoaded().subscribe(null, () => {

      // });
    } catch (error) {
      console.error(error);
    }
    return () => {
      try {
        tvWidget && tvWidget.unsubscribe('drawing_event', onDraw);
      } catch (err) {
        console.warn("Couldn't unmount", err);
      }
    };
  }, [symbol, tvWidget]);

  useEffect(() => {
    if (!tvWidget || !chartDataLoaded) return;
    positionLineRef.current?.remove();
    positionLineRef.current = undefined;
    liquidationLineRef.current?.remove();
    liquidationLineRef.current = undefined;
    if (!hasAuth) return;
    try {
      const chart = tvWidget.chart();
      chart.dataReady(() => {
        const position = account.positions[symbol];
        if (!position) return;
        const pnl = getPositionPnl(symbol, account, markets);
        const profitable = pnl.bigint >= 0;
        positionLineRef.current = chart
          .createPositionLine()
          .setText(
            `PNL ${formatNumber(pnl.decimal, { showChange: true, digits: 2 })}`,
          )
          .setProtectTooltip('Protect position')
          .setCloseTooltip('Close position')
          .setReverseTooltip('Reverse position!')
          .setQuantity(bigIntToDecimalStr(position.size, sizeDecimals))
          .setPrice(bigIntToNum(position.cost / position.size, priceDecimals))
          .setLineColor(profitable ? '#56f5be' : '#f32b46')
          .setBodyBackgroundColor(profitable ? '#56f5be' : '#f32b46')
          .setBodyBorderColor(profitable ? '#56f5be' : '#f32b46')
          .setQuantityBackgroundColor(profitable ? '#56f5be' : '#f32b46')
          .setQuantityBorderColor(profitable ? '#56f5be' : '#f32b46')
          .setBodyTextColor(profitable ? '#000' : '#fff')
          .setQuantityTextColor(profitable ? '#000' : '#fff')
          .setLineStyle(3);
        liquidationLineRef.current = chart
          .createPositionLine()
          .setText('Liq. Price')
          .setPrice(
            parseFloat(getPositionLiqPrice(symbol, account, markets).decimal),
          )
          .setQuantity('')
          .setLineColor('#f32b46')
          .setBodyBackgroundColor('#f32b46')
          .setBodyBorderColor('#f32b46')
          .setQuantityBackgroundColor('#f32b46')
          .setQuantityBorderColor('#f32b46')
          .setBodyTextColor('#fff')
          .setQuantityTextColor('#fff')
          .setLineStyle(3);
      });
    } catch (err) {
      console.warn('ERROR', err);
    }
  }, [
    chartDataLoaded,
    symbol,
    account.positions[symbol],
    hasAuth,
    sizeDecimals,
    priceDecimals,
  ]);

  useEffect(() => {
    if (!tvWidget || !chartDataLoaded) return;
    orderLines.current.forEach((line) => line.remove());
    orderLines.current = [];
    if (!hasAuth) return;
    try {
      const chart = tvWidget.chart();
      chart.dataReady(() => {
        orders.forEach((order) => {
          const priceStr = formatNumber(
            bigIntToDecimalStr(order.limitPrice, priceDecimals),
            { digits: 2 },
          );
          const isPositive =
            order.orderType === OrderType.LIMIT
              ? order.isBuy
              : order.orderType === OrderType.TAKE_PROFIT;
          orderLines.current = [
            ...orderLines.current,
            chart
              .createOrderLine()
              .setText(
                order.orderType === OrderType.LIMIT
                  ? `LIMIT ${order.isBuy ? 'BUY' : 'SELL'}: ${priceStr}`
                  : `${order.orderType === OrderType.TAKE_PROFIT ? 'TP' : 'SL'}: ${priceStr}`,
              )
              .setQuantity(bigIntToDecimalStr(order.size, sizeDecimals))
              .setPrice(bigIntToNum(order.limitPrice, priceDecimals))
              .setLineColor(isPositive ? '#56f5be' : '#f32b46')
              .setBodyBackgroundColor(isPositive ? '#56f5be' : '#f32b46')
              .setBodyBorderColor(isPositive ? '#56f5be' : '#f32b46')
              .setQuantityBackgroundColor(isPositive ? '#56f5be' : '#f32b46')
              .setQuantityBorderColor(isPositive ? '#56f5be' : '#f32b46')
              .setBodyTextColor(isPositive ? '#000' : '#fff')
              .setQuantityTextColor(isPositive ? '#000' : '#fff')
              .setLineStyle(3),
          ];
        });
      });
    } catch (err) {
      console.warn("Couldn't active orders lines: ", err);
    }
  }, [chartDataLoaded, symbol, hasAuth, orders, sizeDecimals, priceDecimals]);

  useEffect(() => {
    if (!tvWidget || !hasAuth) return;

    // refresh marks when last historical order changes
    const unsubscribe = useOrdersStore.subscribe((state, prevState) => {
      const { filledOrderIds: currentFilledOrderIds } = state;
      const { filledOrderIds: previousFilledOrderIds } = prevState;

      const hasNewFilledOrder = Array.from(currentFilledOrderIds).some(
        (id) => !previousFilledOrderIds.has(id),
      );
      if (hasNewFilledOrder) {
        try {
          const chart = tvWidget.chart();
          chart.refreshMarks();
        } catch (err) {
          console.warn("Couldn't refresh marks: ", err);
        }
      }
    });

    return () => unsubscribe();
  }, [tvWidget, hasAuth]);

  useEffect(() => {
    if (!tvWidget) return;
    // refresh marks based on the auth state
    try {
      const chart = tvWidget.chart();
      if (hasAuth) {
        chart.refreshMarks();
      } else {
        chart.clearMarks();
        positionLineRef.current?.remove();
        positionLineRef.current = undefined;
        liquidationLineRef.current?.remove();
        liquidationLineRef.current = undefined;
        orderLines.current.forEach((line) => line.remove());
        orderLines.current = [];
      }
    } catch (err) {
      console.warn("Couldn't refresh marks: ", err);
    }
  }, [tvWidget, hasAuth]);

  useEffect(() => {
    if (!hasAuth) return;
    try {
      const pnl = getPositionPnl(symbol, account, markets);
      const profitable = pnl.bigint >= 0;
      positionLineRef.current
        ?.setText(
          `PNL ${formatNumber(pnl.decimal, { showChange: true, digits: 2 })}`,
        )
        .setLineColor(profitable ? '#56f5be' : '#f32b46')
        .setBodyBackgroundColor(profitable ? '#56f5be' : '#f32b46')
        .setBodyBorderColor(profitable ? '#56f5be' : '#f32b46')
        .setQuantityBackgroundColor(profitable ? '#56f5be' : '#f32b46')
        .setQuantityBorderColor(profitable ? '#56f5be' : '#f32b46')
        .setBodyTextColor(profitable ? '#000' : '#fff')
        .setQuantityTextColor(profitable ? '#000' : '#fff');
      liquidationLineRef.current?.setPrice(
        parseFloat(getPositionLiqPrice(symbol, account, markets).decimal),
      );
    } catch (err) {
      console.warn("Couldn't modify the position line text - widget unmounted");
    }
  }, [
    hasAuth,
    priceDecimals,
    sizeDecimals,
    markets[symbol].marketData.markPrice,
  ]);

  return (
    <div className="h-full flex-1">
      <div id="chart-container" className="h-full w-full" />
    </div>
  );
}
