import { useState, useEffect } from 'react';
import { fetchTime } from '@/lib/api/get-time';

export const useAccurateTimeCheck = () => {
  const [isAccurate, setIsAccurate] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [offset, setOffset] = useState<number>(0);

  useEffect(() => {
    const checkAccurateTime = async () => {
      try {
        const accurateTime = await fetchTime();
        const _isAccurate = Math.abs(accurateTime - Date.now()) < 5_000;

        setIsAccurate(_isAccurate);
        setOffset(!_isAccurate ? accurateTime - Date.now() : 0);

        setLoading(false);
        return;
      } catch (err) {
        console.error('Time fetch failed:', err);
      }
      setError('Failed to fetch time from all sources');
      setLoading(false);
    };

    checkAccurateTime();
  }, []);

  return { loading, error, isAccurate, offset };
};
