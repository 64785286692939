import { useEffect, useState } from 'react';
import { useAccountStore } from '@/store/use-account-store';
import {
  clearPrivateApiHeaders,
  updatePrivateApiHeaders,
} from '@/lib/api-clients/rest-client';
import { useAccount } from 'wagmi';
import { useOrdersStore } from '@/store/use-orders-store';
import { useTransfersStore } from '@/store/use-transfers-store';
import { useExchangeInfo } from '@/features/markets/api/get-exchange-info';
import { useQueryClient } from '@tanstack/react-query';
import { ErrorCode, ErrorMsgs, LPOrderType, OrderStatus } from '@/types/enums';
import { useListenKey, useRefreshListenKey } from '@/lib/api/listen-key';
import { toast } from 'sonner';
import { Overlay } from '@/components/ui';
import { logConsole } from '@/lib/utils';
import { useWebSocketStore } from '@/store/use-websocket-store';

export const ApiProvider = ({ children }: { children: React.ReactNode }) => {
  const MAX_RETRIES = 5;

  const { status, address: userAddress } = useAccount();

  const [retryCount, setRetryCount] = useState(0);

  const {
    hasAuth,
    _hasHydrated,
    authParams,
    setHasAuth,
    clearAccountStore,
    setAuthHydrated,
    getAccurateTime,
  } = useAccountStore((state) => ({
    authParams: state.authParams,
    setHasAuth: state.setHasAuth,
    hasAuth: state.hasAuth,
    _hasHydrated: state._hasHydrated,
    clearAccountStore: state.clearAccountStore,
    setAuthHydrated: state.setAuthHydrated,
    getAccurateTime: state.getAccurateTime,
  }));

  const currentTimestamp = getAccurateTime();

  const keyExpiry = authParams?.listenKeyExpiry ?? 0; // in case of null, subtract and comparision works weird.

  const queryClient = useQueryClient();

  const {
    mutateAsync: getListenKey,
    error: _listenKeyError,
    reset: resetListenKey,
  } = useListenKey(); // if this error object is not null and is unauthorized, set to false
  const listenKeyError = _listenKeyError as unknown as ErrorCode;

  const {
    mutateAsync: refreshListenKey,
    error: _refreshListenKeyError,
    isPending: isListenKeyPending,
    reset: resetRefreshListenKey,
  } = useRefreshListenKey();
  const refreshListenKeyError = _refreshListenKeyError as unknown as ErrorCode;

  useEffect(() => {
    if (
      hasAuth &&
      (listenKeyError === ErrorCode.UNAUTHORIZED ||
        refreshListenKeyError === ErrorCode.UNAUTHORIZED)
    ) {
      clearPrivateApiHeaders(['xrestservermm', 'X-API-KEY']);
      setHasAuth(false);
      resetListenKey();
      resetRefreshListenKey();
    }
  }, [hasAuth, listenKeyError, refreshListenKeyError]);

  useEffect(() => {
    if (
      hasAuth &&
      !isListenKeyPending &&
      listenKeyError !== ErrorCode.ACCOUNT_NOT_FOUND &&
      (!authParams?.listenKey ||
        authParams.listenKeyExpiry <= currentTimestamp) &&
      retryCount < MAX_RETRIES
    ) {
      const timeout = setTimeout(() => {
        getListenKey();
        setRetryCount((prevRetryCount) => prevRetryCount + 1);
      }, 2000); // Retry after 2 seconds

      return () => clearTimeout(timeout); // Cleanup timeout on unmount or dependency change
    }

    if (retryCount >= MAX_RETRIES) {
      toast.error('Unable to retrieve Listen Key after multiple attempts.');
    }
  }, [
    hasAuth,
    isListenKeyPending,
    listenKeyError,
    authParams?.listenKey,
    authParams?.listenKeyExpiry,
    retryCount,
  ]);

  useEffect(() => {
    if (!hasAuth || listenKeyError === ErrorCode.ACCOUNT_NOT_FOUND) {
      setRetryCount(0); // Reset retries when not authorized or account not found
    }
  }, [hasAuth, listenKeyError]);

  useEffect(() => {
    let refreshTimeoutId: NodeJS.Timeout;

    if (authParams?.listenKey && keyExpiry > currentTimestamp) {
      refreshTimeoutId = setTimeout(() => {
        logConsole(false, 'Refresh Listen Key');
        refreshListenKey();
      }, keyExpiry - currentTimestamp);
    }

    return () => {
      clearTimeout(refreshTimeoutId);
    };
  }, [authParams?.listenKey, keyExpiry]);

  useEffect(() => {
    if (!_hasHydrated || status === 'connecting' || status === 'reconnecting')
      return;

    if (
      status === 'connected' &&
      !hasAuth &&
      authParams &&
      authParams.address === userAddress
    ) {
      // Connected to correct account
      // logConsole(false,'Connected to correct account, loading auth from storage...');

      updatePrivateApiHeaders({
        xrestservermm: `restserver${authParams.accountGroup}`,
        'X-API-KEY': authParams.apiKey,
      });

      setHasAuth(true);
    } else if (
      (status === 'connected' &&
        authParams &&
        authParams.address !== userAddress) ||
      (status === 'disconnected' && hasAuth)
    ) {
      // Connected to wrong account / switched account / disconnected / auth expired
      // logConsole(false,
      //   'Connected to wrong account or switched account or disconnected, clearing auth from storage...',
      // );
      const { clearTransfersStore } = useTransfersStore.getState();
      const { clearOrdersStore } = useOrdersStore.getState();
      clearAccountStore();
      clearTransfersStore();
      clearOrdersStore();
      useAccountStore.persist.clearStorage();
      clearPrivateApiHeaders(['xrestservermm', 'X-API-KEY']);
      setHasAuth(false);

      queryClient.removeQueries({
        queryKey: [
          'lpOrders',
          { orderType: LPOrderType.SCHEDULE_WITHDRAW, status: OrderStatus.NEW },
        ],
        exact: true,
      });
    }

    setAuthHydrated(true);
  }, [_hasHydrated, status, userAddress, listenKeyError]);

  const { isPending, isError } = useExchangeInfo();
  if (isPending || isError) {
    return <> </>;
  }

  return <>{children}</>;
};
