import React, { useState, useEffect, useMemo } from 'react';
import { FormControl } from '@/features/trade/order/components/order-form-components';
import { Input } from '@/components/ui';
import {
  formatNumber,
  parseDecimalToBigInt,
  validatedDecimalStr,
} from '@/utils/value-format';
import { useMarketStore } from '@/store/use-markets-store';
import { getLimitPriceFromMaxSlippage } from '../utils/math';
import { useStore } from 'zustand';
import type { InputHTMLAttributes } from 'react';

interface MaxSlippageInputProps extends InputHTMLAttributes<HTMLInputElement> {
  symbol: string;
  isBuy: boolean;
  onLimitPriceChange: (limitPrice: bigint) => void;
  defaultValue?: number;
  isSubmitSuccessful?: boolean;
}

export const MaxSlippageInput: React.FC<MaxSlippageInputProps> = ({
  symbol,
  isBuy,
  onLimitPriceChange,
  isSubmitSuccessful = false,
  defaultValue = 5,
  ...props
}) => {
  const marketData = useStore(
    useMarketStore(symbol),
    (state) => state.marketData,
  );

  const marketSpec = useMemo(() => {
    return useMarketStore(symbol).getState().marketSpec;
  }, [symbol]);

  const [maxSlippageInput, setMaxSlippageInput] = useState<string>('');
  const handleMaxSlippageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validatedValue = validatedDecimalStr(e.target.value, 2, 4);
    if (Number(validatedValue) > 100) return;
    setMaxSlippageInput(validatedValue);
  };

  useEffect(() => {
    let maxSlippagePct: bigint;
    if (maxSlippageInput === '') {
      maxSlippagePct = parseDecimalToBigInt(defaultValue.toString(), 2n);
    } else {
      maxSlippagePct = parseDecimalToBigInt(maxSlippageInput, 2n); // 2 decimal precision of the percent
    }
    const limitPrice = getLimitPriceFromMaxSlippage(
      isBuy,
      maxSlippagePct,
      marketData,
      marketSpec,
    ).bigint;

    onLimitPriceChange(limitPrice);
  }, [maxSlippageInput, marketData.markPrice, isSubmitSuccessful]);

  return (
    <>
      <FormControl>
        <Input
          className="max-w-[7ch] rounded-none bg-vestgrey-800 font-mono text-base text-foreground"
          placeholder={formatNumber(defaultValue, { digits: 2 })}
          value={maxSlippageInput}
          onChange={handleMaxSlippageChange}
          {...props}
        />
      </FormControl>
      <p className="px-2">%</p>
    </>
  );
};
