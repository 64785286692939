import { ErrorCode, MsgHandler } from '@/types';
import { logConsole, parseWsMsg } from '../utils';
import { useAccountStore } from '@/store/use-account-store';

const MAX_RETRIES = 5;
const BASE_DELAY = 1000;
const MAX_DELAY = 60000;
const PING_INTERVAL = 5_000;
const PONG_TIMEOUT = 3_000;

/**
 * Parses a Brotli-encoded WebSocket message into a WsMsg
 *
 * @param event
 * @returns
 */

/**
 * Robust Websocket
 *
 * Abstracts over raw WebSocket connection with heartbeat, reconnection logic, and message queueing.
 *
 * Key features:
 * - safeConnect() is the primary entrypoint that allows you to also switch the underlying url
 * - addMessageHandler() allows you to attach external message handlers
 */
export class RobustWebsocket {
  public isHealthy: boolean = true; // flag to indicate connection is healthy (if pings are successful and subscriptions return acks)
  private ws: WebSocket | null = null;
  private msgHandlers: Record<string, MsgHandler> = {};
  private msgsOutQueue: any[] = []; // NOTE: not JSON stringified

  private ackTimeoutIds: Record<number, NodeJS.Timeout> = {};
  private pingTimeoutId: NodeJS.Timeout | null = null;
  private pongTimeoutId: NodeJS.Timeout | null = null;
  private retryTimeoutId: NodeJS.Timeout | null = null;

  private id: number; // internal id to help with debugging
  private url: string = '';
  private retries: number = 0;
  private nonce: number = useAccountStore.getState().getAccurateTime();
  private reconnect: boolean = true;

  private onHealthChange: (isHealthy: boolean) => void;
  private onConnected: () => void;
  private onError: (errorCode: ErrorCode | string) => void;
  private onDisconnected: () => void;

  constructor(
    options: {
      url?: string;
      onHealthChange?: (isHealthy: boolean) => void;
      onConnected?: () => void;
      onError?: (errorCode: ErrorCode | string) => void;
      onDisconnected?: () => void;
    } = {},
  ) {
    this.id = useAccountStore.getState().getAccurateTime();
    this.url = options.url ?? '';
    this.onHealthChange =
      options.onHealthChange ?? ((isHealthy: boolean) => {});
    this.onConnected = options.onConnected ?? (() => {});
    this.onError = options.onError ?? ((errorCode: ErrorCode | string) => {});
    this.onDisconnected = options.onDisconnected ?? (() => {});
  }

  attachOnError(onError: (errorCode: ErrorCode | string) => void) {
    this.onError = onError;
  }

  isReady() {
    return this.ws?.readyState === WebSocket.OPEN && this.isHealthy;
  }

  toString() {
    return `WS ${this.id} (${this.url})`;
  }

  ping() {
    this.send({ method: 'PING', id: this.nextNonce(), params: [] });

    this.pongTimeoutId = setTimeout(() => {
      this.setHealth(false);
    }, PONG_TIMEOUT);
  }

  private schedulePing() {
    if (this.pingTimeoutId) clearTimeout(this.pingTimeoutId);
    this.pingTimeoutId = setTimeout(() => this.ping(), PING_INTERVAL);
  }

  setHealth(isHealthy: boolean) {
    if (this.isHealthy === isHealthy) return;

    this.reconnect = true;

    this.isHealthy = isHealthy;
    this.onHealthChange(isHealthy);

    if (!isHealthy) {
      logConsole(
        true,
        `${this}: Connection is unhealthy. Initiating reconnection...`,
      );

      this.disconnect();
    } else {
      logConsole(false, `${this}: Connection is healthy.`);
    }
  }

  safeConnect(url?: string) {
    if (url === undefined && this.url === '') {
      logConsole(true, `${this}: No URL provided.`);
      return;
    }

    if (this.retries >= MAX_RETRIES) {
      logConsole(true, `${this}: Max retries reached. No further attempts.`);
      this.onError(ErrorCode.MAX_RETRIES_EXCEEDED); // Notify about the failure.
      return;
    }

    if (
      this.ws &&
      (this.ws.readyState === WebSocket.CONNECTING ||
        this.ws.readyState === WebSocket.OPEN)
    ) {
      console.warn(`${this}: Already connected or connecting.`);
      return;
    }

    if (this.retryTimeoutId !== null) {
      console.warn(`${this}: Retry already scheduled.`);
      return;
    }
    if (this.retries === 0) {
      this.retries++;
      this._connect(url ?? this.url);
      return;
    }

    const delay =
      Math.min(BASE_DELAY * 2 ** this.retries, MAX_DELAY) +
      Math.random() * 1000;

    if (this.retryTimeoutId) clearTimeout(this.retryTimeoutId);

    this.retryTimeoutId = setTimeout(() => {
      logConsole(
        false,
        `${this}: Retrying connection in ${delay / 1000} seconds... (Attempt ${this.retries})`,
      );
      this.retries++;
      this._connect(url ?? this.url);
      this.retryTimeoutId = null;
    }, delay);
  }

  _connect(url: string) {
    if (this.ws) {
      logConsole(false, `${this}: Closing previous WebSocket instance.`);
      this.disconnect(false); // Ensure old WebSocket instance is cleaned up
    }

    this.ws = new WebSocket(url);

    this.ws.onopen = () => {
      logConsole(false, `${this}: connected`);
      this.url = url;
      this.msgsOutQueue.forEach(([msg, callback]) => this.send(msg, callback));
      if (this.pongTimeoutId) clearTimeout(this.pongTimeoutId);
      this.schedulePing();
      this.setHealth(true);
      this.onConnected();
    };

    this.ws.onclose = () => {
      logConsole(false, `${this}: disconnected`);
      if (this.reconnect) setTimeout(() => this.safeConnect(), 1000);
      this.onDisconnected();
    };

    this.ws.onerror = (event) => {
      const error = event as WebSocketErrorEvent;
      logConsole(true, `${this}: error:`, error);
      this.onError(error.message ?? 'WebSocket error');
      this.reconnect = false;
      this.disconnect();
    };

    this.ws.onmessage = (event) => {
      parseWsMsg(event).then((msg) => {
        if ('error' in msg) {
          logConsole(true, `${this}: error in msg`, msg.error);
          this.onError(ErrorCode.UNAUTHORIZED); // TODO: change to the specific error
          this.reconnect = false;
          this.disconnect();
          return;
        }

        if ('code' in msg) {
          logConsole(true, `${this}: error as code`, msg);
          this.onError(msg.code as ErrorCode);
          this.reconnect = false;
          this.disconnect();
          return;
        }

        if ('type' in msg && msg.type === 'channel.error') {
          logConsole(true, 'channel error', msg);
          this.reconnect = false;
          this.disconnect();
          return;
        }

        if ('data' in msg && msg.data === 'pong') {
          // logConsole(false, `Pong received. Connection is healthy.`);
          if (this.pongTimeoutId) {
            clearTimeout(this.pongTimeoutId);
            this.pongTimeoutId = null;
          }
          this.schedulePing(); // Schedule the next ping.
          return;
        }

        if ('result' in msg) {
          clearTimeout(this.ackTimeoutIds[msg.id]);
          delete this.ackTimeoutIds[msg.id];
        } else {
          Object.values(this.msgHandlers).forEach((handler) => {
            if (handler(msg)) {
              return;
            }
          });
        }

        // Extend ping timeout if we receive another msg - Seems like this ping whenever there's message was burdening the server.
        // if (this.pongTimeoutId) clearTimeout(this.pongTimeoutId);
        // this.schedulePing(); // Schedule the next ping.
      });
    };
  }

  disconnect(_reconnect = true) {
    this.reconnect = _reconnect && this.reconnect;
    if (this.ws === null) {
      console.warn(`${this}: Already disconnected.`);
      return;
    }
    if (this.ws?.readyState === WebSocket.CONNECTING) {
      console.warn(`${this}: Still Connecting.`);

      setTimeout(() => {
        this.closeWithTimeouts();
      }, 1000);

      return;
    }

    this.closeWithTimeouts();
  }
  private closeWithTimeouts() {
    logConsole(false, `${this}: Disconnecting WebSocket.`);
    this.ws?.close();
    this.ws = null;

    this.clearTimeouts();
    this.msgsOutQueue = [];
    this.msgHandlers = {};
  }

  private clearTimeouts() {
    if (this.pingTimeoutId) clearTimeout(this.pingTimeoutId);
    if (this.pongTimeoutId) clearTimeout(this.pongTimeoutId);
    if (this.retryTimeoutId) clearTimeout(this.retryTimeoutId);

    Object.values(this.ackTimeoutIds).forEach((timeoutId) =>
      clearTimeout(timeoutId),
    );
    this.pingTimeoutId = null;
    this.pongTimeoutId = null;
    this.retryTimeoutId = null;
    this.ackTimeoutIds = {};
  }

  /**
   * Send a message to the WebSocket server. If the WebSocket is not open, the message is queued.
   *
   * @param data - The data to send.
   * @param callback - An optional callback to execute after the message is sent.
   */
  send(data: any, callback?: () => void) {
    if (this.ws?.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify(data));
      callback?.();
    } else {
      this.msgsOutQueue.push([data, callback]);
    }
  }

  /**
   * Add a message handler for a specific event. The message handler should return true to stop other handlers from processing.
   *
   * @param handlerId - The identifier for the message handler.
   * @param handler - The message handler function.
   */
  addMessageHandler(handlerId: string, handler: MsgHandler) {
    this.msgHandlers[handlerId] = handler;
  }

  removeMessageHandler(handlerId: string) {
    delete this.msgHandlers[handlerId];
  }

  handleSubAck(id: number) {
    const timeoutId = setTimeout(() => {
      logConsole(true, `${this}: no ack back from ws`, id);
      this.setHealth(false);
    }, PONG_TIMEOUT);
    this.ackTimeoutIds[id] = timeoutId;
  }

  /**
   * Increment the nonce by 1 or set to current timestamp. Ensures monotonicity.
   */
  nextNonce() {
    const now = useAccountStore.getState().getAccurateTime();
    if (now > this.nonce) {
      this.nonce = now;
      return now;
    } else {
      const newNonce = this.nonce + 1;
      this.nonce = newNonce;
      return newNonce;
    }
  }

  subscribeToChannels(params: string[]) {
    const nonce = this.nextNonce();

    const subscriptionPayload = {
      method: 'SUBSCRIBE',
      params,
      id: nonce,
    };
    this.send(
      subscriptionPayload,
      () => {},
      // this.handleSubAck(subscriptionPayload.id),
    );
  }

  unsubscribeFromChannels(params: string[]) {
    const nonce = this.nextNonce();

    const unsubscriptionPayload = {
      method: 'UNSUBSCRIBE',
      params,
      id: nonce,
    };

    this.send(
      unsubscriptionPayload,
      () => {},
      // this.handleSubAck(unsubscriptionPayload.id),
    );
  }
}
