import { Link, useLocation } from 'react-router-dom';
import { clsx } from 'clsx';
import { RKConnectButton } from './connect-button';

import VestIcon from '@/assets/branding/vest-icon.svg';

export const Header = () => {
  const { pathname } = useLocation();
  return (
    <header className="hidden h-20 shrink-0 grid-cols-5 border-b border-border bg-background md:flex">
      {/* Vest and Links Wrapper */}
      <div className="relative col-span-4 grid flex-1 grid-cols-[1fr_max-content_max-content] font-mono">
        <div className="flex gap-2 px-8">
          <a
            href="https://vest.exchange/"
            target="_blank"
            rel="noreferrer"
            className="mr-12 flex items-center gap-2"
          >
            <img src={VestIcon} alt="Vest Icon" />
            <svg
              width="48"
              height="16"
              viewBox="0 0 54 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.3652 3.66785H10.4172C10.3355 3.66785 10.2634 3.71835 10.2355 3.79458L6.34634 14.6816L2.44467 3.79458C2.41775 3.71835 2.34468 3.66785 2.26391 3.66785H0.32942C0.195775 3.66785 0.103473 3.79935 0.148662 3.92323L5.1618 17.6128H7.5328L12.5469 3.92323C12.5921 3.79935 12.4998 3.66785 12.3661 3.66785H12.3652Z"
                fill="#F5F5F5"
              />
              <path
                d="M24.7904 4.24819C23.7952 3.60307 22.5905 3.28003 21.1752 3.28003C19.7599 3.28003 18.6235 3.58401 17.612 4.19006C16.5996 4.79707 15.8121 5.65851 15.2468 6.77247C14.6824 7.88738 14.4007 9.20716 14.4007 10.7299C14.4007 12.2527 14.6872 13.4572 15.2602 14.5464C15.8333 15.6346 16.6342 16.4827 17.6639 17.0897C18.6927 17.6967 19.8984 17.9997 21.2791 17.9997C22.6597 17.9997 23.8741 17.6624 24.9731 16.9868C26.0711 16.3102 27.1912 15.0581 27.6287 13.4677L25.1605 13.4696C24.774 14.21 24.2539 14.7827 23.5981 15.1848C22.9116 15.6069 22.104 15.8175 21.1752 15.8175C19.7936 15.8175 18.7302 15.3659 17.9831 14.4625C17.337 13.6802 16.9707 12.6215 16.8832 11.2855H27.6758C27.745 9.63312 27.5306 8.20851 27.0306 7.01165C26.5316 5.81574 25.7845 4.89427 24.7904 4.24819ZM16.9332 9.43968C17.0726 8.30857 17.4226 7.39948 17.9831 6.71434C18.7302 5.8024 19.8195 5.34595 21.2531 5.34595C22.5905 5.34595 23.5981 5.77 24.276 6.61809C24.8115 7.28895 25.1432 8.22852 25.2711 9.43968H16.9332Z"
                fill="#F5F5F5"
              />
              <path
                d="M34.134 5.81097C34.7023 5.45839 35.4436 5.29926 36.355 5.33356C37.3194 5.35929 38.1078 5.58704 38.7193 6.01776C39.3318 6.44847 39.6942 7.03738 39.8077 7.78637H42.2306C42.1595 6.7515 41.7768 5.82622 41.2605 5.21064C40.7432 4.59505 40.0635 4.11955 39.2212 3.78412C38.3789 3.44774 37.4232 3.28003 36.355 3.28003C35.2868 3.28003 34.334 3.44774 33.5216 3.78412C32.7091 4.11955 32.0774 4.59505 31.6265 5.21064C31.1746 5.82622 30.9487 6.54281 30.9487 7.36041C30.9487 8.02364 31.1006 8.58682 31.4044 9.05184C31.7082 9.51686 32.2168 9.92566 32.9293 10.2782C33.6408 10.6318 34.6177 10.972 35.8599 11.2988C37.0069 11.5999 37.8646 11.8582 38.4328 12.0735C39.002 12.2889 39.3779 12.5185 39.5606 12.7644C39.7423 13.0093 39.8337 13.3304 39.8337 13.7259C39.8337 14.4063 39.5606 14.9437 39.0126 15.3401C38.4655 15.7356 37.7098 15.9338 36.7464 15.9338C35.7301 15.9338 34.8782 15.7127 34.1927 15.2687C33.5062 14.8256 33.0677 14.2252 32.8764 13.4677H30.4487C30.6448 15.0485 31.4063 16.1682 32.4793 16.8962C33.5523 17.6233 34.9263 17.9873 36.6031 17.9873C38.2799 17.9873 39.7337 17.6014 40.7326 16.8314C41.7316 16.0615 42.2306 15.0047 42.2306 13.6611C42.2306 12.9388 42.0787 12.3299 41.7749 11.8344C41.4711 11.3398 40.9605 10.9091 40.2442 10.5432C39.5279 10.1772 38.5482 9.8399 37.3059 9.52925C36.2031 9.25386 35.3628 9.00896 34.7849 8.7936C34.2081 8.57824 33.8129 8.35049 33.5994 8.10941C33.387 7.86832 33.2802 7.57958 33.2802 7.24416C33.2802 6.64192 33.5648 6.16451 34.134 5.81097Z"
                fill="#F5F5F5"
              />
              <path
                d="M49.7532 5.56516H54.0001V3.6679H49.7532V0.190716C49.7532 0.0306261 49.5667 -0.057995 49.4408 0.0420611L47.5063 1.57626C47.4611 1.61247 47.4342 1.66678 47.4342 1.72491V3.6679H44.5805V5.56516H47.4342V13.845C47.4342 17.2755 49.9446 17.6129 51.6002 17.6129H54.0001V15.6346H51.5685C50.7176 15.6346 49.7532 15.2849 49.7532 13.704V5.56516Z"
                fill="#F5F5F5"
              />
            </svg>
          </a>
          <Link
            to="/trade/TRUMP-PERP"
            className={clsx(
              'flex items-center gap-0.5 px-8 text-center text-sm',
              {
                'text-font': pathname.startsWith('/trade'),
                'text-vestgrey-100': !pathname.startsWith('/trade'),
              },
            )}
          >
            TRADE
          </Link>
          <Link
            to="/markets"
            className={clsx(
              'flex items-center gap-0.5 px-8 text-center text-sm',
              {
                'text-font': pathname === '/markets',
                'text-vestgrey-100': pathname !== '/markets',
              },
            )}
          >
            MARKETS
          </Link>
          <Link
            to="/portfolio"
            className={clsx(
              'flex items-center gap-0.5 px-8 text-center text-sm',
              {
                'text-font': pathname === '/portfolio',
                'text-vestgrey-100': pathname !== '/portfolio',
              },
            )}
          >
            PORTFOLIO
          </Link>
          <Link
            to="/liquidity-providing"
            className={clsx(
              'flex items-center gap-0.5 px-8 text-center text-sm uppercase',
              {
                'text-font': pathname === '/liquidity-providing',
                'text-vestgrey-100': pathname !== '/liquidity-providing',
              },
            )}
          >
            Liquidity Providing
          </Link>
          <Link
            to="/referrals"
            className={clsx(
              'flex items-center gap-0.5 px-8 text-center text-sm uppercase',
              {
                'text-font': pathname === '/referrals',
                'text-vestgrey-100': pathname !== '/referrals',
              },
            )}
          >
            Referrals
          </Link>
          {/* <Link
            to="/referrals"
            className={clsx(
              'flex items-center gap-0.5 px-8 text-center text-sm uppercase',
              {
                'text-font': pathname === '/referrals',
                'text-vestgrey-100': pathname !== '/referrals',
              },
            )}
          >
            Referrals
          </Link> */}
          {/* <Link
            to="/referrals"
            className={clsx(
              'flex items-center gap-0.5 px-8 text-center text-sm uppercase',
              {
                'text-font': pathname === '/referrals',
                'text-vestgrey-100': pathname !== '/referrals',
              },
            )}
          >
            Referrals
          </Link> */}
          {/* <Link
            to="/markets"
            className={clsx(
              'flex items-center gap-0.5 px-8 text-center text-sm',
              {
                'text-font': pathname === '/markets',
                'text-vestgrey-100': pathname !== '/markets',
              },
            )}
          >
            MARKETS
          </Link> */}
        </div>
        {/* <div className="flex items-center gap-4 border-l border-border px-8">
          <Link
            to="https://x.com/vestexchange"
            target="_blank"
            rel="noreferrer"
          >
            <img src={XIcon} alt="Caution Icon" className="h-6 w-6" />
          </Link>
          <Link
            to="https://medium.com/@VestExchange"
            target="_blank"
            rel="noreferrer"
          >
            <img src={MediumIcon} alt="Medium Icon" className="h-6 w-6" />
          </Link>
          <Link
            to="https://discord.com/invite/vestexchange"
            target="_blank"
            rel="noreferrer"
          >
            <img src={DiscordIcon} alt="Discord Icon" className="h-6 w-6" />
          </Link>
        </div> */}
        {/* <div className="grid place-content-center border-l border-border px-8">
          <svg
            width="16"
            height="16"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.8333 0H9.16667V3.33333H10.8333V0ZM0 9.16667V10.8333H3.33333V9.16667H0ZM20 9.16667V10.8333H16.6667V9.16667H20ZM10.8333 20H9.16667V16.6667H10.8333V20ZM6.66667 5H13.3333V6.66667H6.66667V5ZM5 6.66667H6.66667V13.3333H5V6.66667ZM6.66667 15V13.3333H13.3333V15H6.66667ZM15 13.3333H13.3333V6.66667H15V13.3333ZM16.6667 1.66667H18.3333V3.33333H16.6667V1.66667ZM16.6667 3.33333V5H15V3.33333H16.6667ZM18.3333 18.3333H16.6667V16.6667H18.3333V18.3333ZM16.6667 16.6667H15V15H16.6667V16.6667ZM3.33333 1.66667H1.66667V3.33333H3.33333V5H5V3.33333H3.33333V1.66667ZM1.66667 18.3333H3.33333V16.6667H5V15H3.33333V16.6667H1.66667V18.3333Z"
              fill="#F5F5F5"
            />
          </svg>
        </div> */}
      </div>
      {/* Icons and Wallet Button Wrapper */}
      <div className="relative flex w-fit min-w-[20%] gap-4 border-l border-border px-8 font-mono text-sm">
        <RKConnectButton />
      </div>
    </header>
  );
};
