import { signMessage } from 'viem/accounts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MutationConfig } from '@/lib/api-clients/react-query';
import { z } from 'zod';
import { privateApi } from '@/lib/api-clients/rest-client';
import { ErrorCode, ErrorMsgs, OrderStatus, LPOrderType } from '@/types/enums';
import { useAccountStore } from '@/store/use-account-store';
import { encodeAbiParameters, keccak256 } from 'viem';
import { getLpOrders, LpOrder } from './get-lp-orders';
import { toast } from 'sonner';
import {
  bigIntToDecimalStr,
  formatNumber,
  parseDecimalToBigInt,
} from '@/utils/value-format';
import { getAccountQueryOptions } from '@/features/account/api/get-account';
import { queryClient } from '@/context/provider';
import { COLLATERAL_DECIMALS } from '@/store/use-markets-store';

export const createLpOrderInputSchema = z.object({
  orderType: z.nativeEnum(LPOrderType),
  size: z
    .string()
    .regex(/^[0-9]\d*(\.\d+)?$/, 'Must be a positive number')
    .regex(/^(?!0*\.?0+$)/, 'Must not be zero or all zeros'),
});

export type CreateLpOrderInput = z.infer<typeof createLpOrderInputSchema>;

export type CreateLpOrderResponse = {
  id: string;
};

const createLpOrder = async ({ orderType, size }: CreateLpOrderInput) => {
  const { authParams, nextNonce } = useAccountStore.getState();
  if (!authParams?.signingKey) {
    console.error('No signing key found');
    throw new Error('No signing key found');
  }

  const time = useAccountStore.getState().getAccurateTime();
  const nonce = nextNonce();

  const encodedOrder = encodeAbiParameters(
    [
      { name: 'time', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'orderType', type: 'string' },
      { name: 'size', type: 'string' },
    ],
    [BigInt(time), BigInt(nonce), orderType, size],
  );

  const signature = await signMessage({
    message: { raw: keccak256(encodedOrder) },
    privateKey: authParams.signingKey,
  });

  const body = {
    order: { orderType, size, nonce, time },
    signature,
    recvWindow: 10_000,
  };

  const restPromise = privateApi.post('/lp', body);

  const confirmPromise = restPromise.then((res) => {
    const { id: orderId } = res;
    return new Promise<LpOrder>((resolve, reject) => {
      let pollIntervalId: NodeJS.Timeout;
      let timeoutId: NodeJS.Timeout;

      const cleanup = () => {
        clearInterval(pollIntervalId);
        clearTimeout(timeoutId);
      };

      pollIntervalId = setInterval(() => {
        getLpOrders({ id: orderId })
          .then((data) => {
            if (data.length > 0) {
              const newLpOrder = data[0];
              cleanup();
              resolve(newLpOrder);
              if (
                newLpOrder.orderType === LPOrderType.DEPOSIT &&
                newLpOrder.status === OrderStatus.FILLED
              ) {
                const { authParams } = useAccountStore.getState();
                const { queryKey } = getAccountQueryOptions(authParams);
                queryClient.setQueryData(queryKey, (prev) =>
                  prev
                    ? {
                        ...prev,
                        lp: {
                          ...prev.lp,
                          balance: bigIntToDecimalStr(
                            parseDecimalToBigInt(
                              prev.lp.balance,
                              COLLATERAL_DECIMALS,
                            ) +
                              parseDecimalToBigInt(
                                newLpOrder.size,
                                COLLATERAL_DECIMALS,
                              ),
                            COLLATERAL_DECIMALS,
                          ),
                        },
                      }
                    : prev,
                );
              }
              return;
            }
          })
          .catch((error) => {
            reject(error);
          });
      }, 1_000);

      timeoutId = setTimeout(() => {
        cleanup();
        reject(ErrorCode.INTERNAL_CREATE_ORDER_TIMEOUT);
      }, 10_000); // 10 seconds timeout
    });
  });

  toast.promise(confirmPromise, {
    loading: `${orderType === LPOrderType.DEPOSIT ? 'Depositing...' : 'Withdrawing...'}`,
    success: (data: LpOrder) =>
      `Successfully ${orderType === LPOrderType.DEPOSIT ? 'deposited' : 'requested withdrawal of'} ${formatNumber(data.size, { digits: 2 })} USDC!`,
    error: (error) => {
      return `Order rejected: ${ErrorMsgs[error as ErrorCode]}`;
    },
  });

  return confirmPromise;
};

type UseCreateLpOrderOptions = {
  mutationConfig?: MutationConfig<typeof createLpOrder>;
};

export const useCreateLpOrder = ({
  mutationConfig,
}: UseCreateLpOrderOptions = {}) => {
  const queryClient = useQueryClient();

  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess: (data, variables, context) => {
      const { orderType } = variables;
      if (orderType === LPOrderType.SCHEDULE_WITHDRAW) {
        // Invalidate lp orders history cache
        queryClient.invalidateQueries({
          queryKey: [
            'lpOrders',
            {
              orderType: LPOrderType.SCHEDULE_WITHDRAW,
              status: OrderStatus.NEW,
            },
          ],
        });
      }
    },
    ...restConfig,
    mutationFn: createLpOrder,
  });
};
