import { Button } from '@/components/ui';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import useOpportunities from '@/features/lp/api/get-opportunities';
import LPStats from '@/features/lp/components/lp-stats';
import LPTable from '@/features/lp/components/lp-table';
import { LPTransferDialog } from '@/features/lp/components/lp-transfer-dialog';
import PerformanceChart from '@/features/lp/components/performance-chart';
import {
  ExchangeInfo,
  useExchangeInfo,
} from '@/features/markets/api/get-exchange-info';
import { cn } from '@/lib/utils';
import { useAccountStore } from '@/store/use-account-store';
import { LPOrderType } from '@/types';
import { formatNumber } from '@/utils/value-format';
import { Info } from 'lucide-react';
import { Link } from 'react-router-dom';

const BASE_APR = 12;

export default function LPPage() {
  const { hasAuth } = useAccountStore();
  const { data } = useExchangeInfo();
  const { data: opportunities } = useOpportunities();
  const exchangeInfo = data as ExchangeInfo;

  const igniteAPR = opportunities
    ? Math.round(opportunities.reduce((prev, curr) => prev + curr.apr, 0))
    : 0;

  return (
    <div className="flex h-full flex-col">
      <div className="grid grid-cols-5 items-center border-b border-border bg-background py-10">
        <div className="space-y-10 pl-8">
          <h1 className="text-2xl font-semibold sm:text-3xl lg:text-4xl">
            Liquidity Providing
          </h1>
          <div className="grid grid-cols-2">
            <div className="flex flex-col gap-2">
              <div className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
                Expected APY
              </div>
              <div className="text-font flex flex-1 items-center justify-start gap-2 text-xl font-bold">
                {opportunities ? (
                  <>
                    <span>{BASE_APR + igniteAPR}%</span>
                    <TooltipProvider>
                      <Tooltip delayDuration={0}>
                        <TooltipTrigger>
                          <Info size={14} className="mb-0.5" />
                        </TooltipTrigger>
                        <TooltipContent side="right">
                          <ul className="list-disc pl-4 text-left text-base">
                            <li>Base APY - {BASE_APR}%</li>
                            <li>
                              <Link
                                to="https://app.zksyncignite.xyz/opportunities/zksync-era/VEST/0x7ccF5BbeC69c790D27dA3b5398B9e0d6D6EeC9F3"
                                target="_blank"
                                rel="noreferrer"
                                className="underline"
                              >
                                Ignite APY - {igniteAPR}%
                              </Link>
                            </li>
                          </ul>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </>
                ) : (
                  '-'
                )}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
                TVL
              </div>
              <div
                className={cn(
                  'text-font flex flex-1 items-center justify-start text-xl font-bold',
                )}
              >
                {formatNumber(exchangeInfo.exchange.lp, {
                  digits: 2,
                  compact: true,
                  round: 'ceil',
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-3 flex flex-col gap-2 px-8">
          <p className="font-semibold text-vestgrey-100">
            Provide backstop liquidity to Vest Exchange
          </p>
          <p className="max-w-7xl text-vestgrey-100">
            The lock up period is 8 hours. Yield comes from funding rates and
            premiums paid by traders.{' '}
            <a
              href="https://docs.vest.exchange/overview/liquidity-providers"
              className="font-medium underline"
              target="_blank"
            >
              More information
            </a>
          </p>
        </div>
        <div className="px-8">
          <LPTransferDialog orderType={LPOrderType.DEPOSIT}>
            <Button
              disabled={!hasAuth}
              size="lg"
              className="h-auto rounded-none px-12 py-6 font-mono uppercase tracking-wider"
            >
              Provide Liquidity
            </Button>
          </LPTransferDialog>
        </div>
      </div>
      <div className="grid grid-cols-4 grid-rows-[max-content_1fr]">
        <LPStats />
        <PerformanceChart />
      </div>
      <div className="relative flex-1">
        <div className="absolute inset-0 h-full w-full">
          <LPTable />
        </div>
      </div>
    </div>
  );
}
