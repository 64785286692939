import { useMutation } from '@tanstack/react-query';
import { ethers } from 'ethers';
import { signMessage, signTypedData } from '@wagmi/core';
import { useAccountStore } from '@/store/use-account-store';
import {
  publicApi,
  updatePrivateApiHeaders,
} from '@/lib/api-clients/rest-client';
import { wagmiConfig } from '@/lib/wagmi/config';
import { encodeAbiParameters, keccak256 } from 'viem';

type RegisterResponse = {
  apiKey: string;
  accGroup: number;
};

type ExtendedRegisterResponse = RegisterResponse & {
  signingKey: `0x${string}`;
};

export function useRegisterUser() {
  return useMutation({
    mutationFn: async (variables: {
      address: string;
      rememberMe: boolean;
    }): Promise<ExtendedRegisterResponse> => {
      const { address } = variables;
      const wallet = ethers.Wallet.createRandom();
      const signingAddr = wallet.address.toLowerCase() as `0x${string}`;
      const expiryTime =
        Math.floor(useAccountStore.getState().getAccurateTime()) +
        1000 * 3600 * 24 * 14;

      const signature = await signTypedData(wagmiConfig, {
        domain: {
          name: 'VestRouterV2',
          version: '0.0.1',
          verifyingContract: import.meta.env.VITE_ROUTER,
        },
        primaryType: 'SignerProof',
        types: {
          SignerProof: [
            { name: 'approvedSigner', type: 'address' },
            { name: 'signerExpiry', type: 'uint256' },
          ],
        },
        message: {
          approvedSigner: signingAddr,
          signerExpiry: BigInt(expiryTime),
        },
      });

      const data = {
        signingAddr,
        primaryAddr: address.toLowerCase(),
        signature,
        expiryTime: expiryTime, // 2 weeks
      };

      const res = await publicApi.post('/register', data);
      return {
        ...res,
        signingKey: wallet.privateKey as `0x${string}`,
      };
    },
    onSuccess: (
      res: ExtendedRegisterResponse,
      variables: { address: string; rememberMe: boolean },
    ) => {
      const { address, rememberMe } = variables;
      const { setAuthParams, setHasAuth } = useAccountStore.getState();
      setAuthParams({
        address,
        signingKey: res.signingKey,
        apiKey: res.apiKey,
        accountGroup: res.accGroup,
        listenKey: '',
        listenKeyExpiry: 0,
        shouldPersist: rememberMe,
      });
      updatePrivateApiHeaders({
        xrestservermm: `restserver${res.accGroup}`,
        'X-API-KEY': res.apiKey,
      });
      setHasAuth(true);
    },
  });
}
